import React, { FC, Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Badge from "shared/Badge/Badge";
import NcImage from "shared/NcImage/NcImage";
import ModalPhotos from "./ModalPhotos";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import toast, { Toaster } from "react-hot-toast";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  Skeleton,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { config } from "config";
import { TrashIcon, XMarkIcon } from "@heroicons/react/24/solid";
import ButtonPrimary from "shared/Button/ButtonPrimary";
export interface EventDetailPageProps {
  className?: string;
  isPreviewMode?: boolean;
}
interface EventProps {
  adres: string;
  id: number;
  rezervasyonsuz: number;
  category_id: number;
  date: string;
  detail: string;
  photo: string;
  sit_plan_photo: string;
  title: string;
  ucretsiz: string;
  photos: [];
  seans: [];
}
const EventDetailPage: FC<EventDetailPageProps> = ({
  className = "",
  isPreviewMode,
}) => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const [is_button_disabled, setIsButtonDisabled] = useState(true);
  const [is_checkout_disabled, setIsCheckoutDisabled] = useState(false);
  const [is_checkout_loading, setIsCheckoutLoading] = useState(false);
  const [seans_id, setSeansId] = React.useState("");
  const [sira_id, setSiraId] = React.useState("");
  const [koltuk_id, setKoltukId] = React.useState("");
  const [koltuk_siralar, setKoltukSira] = React.useState<any["id"]>([]);
  const [chairs, setChairs] = React.useState<any["id"]>([]);
  const [event_item, setEvent] = React.useState<Partial<EventProps>>({});
  const [basket_items, setBasketItems] = React.useState<any[]>([]);
  const [items_in_basket, setItemsBasket] = React.useState<any["id"]>([]);
  const [loading, setLoading] = React.useState(false);
  const [loadingBasket, setLoadingBasket] = React.useState(false);
  const handleDeleteBasketItem = (id: any) => {
    setLoadingBasket(true);
    setBasketItems(basket_items.filter((item) => item.id !== id));
    setItemsBasket(
      items_in_basket.filter((item: { id: any }) => item.id !== id)
    );
    setLoadingBasket(false);
  };
  const refreshBasket = () => {
    if (basket_items.length > 0) {
      setLoadingBasket(true);
      setItemsBasket([]);
      basket_items.map((item: any, index: number) => {
        fetch(config.apiUrl + config.getChairDetail + "/" + item.id)
          .then((res) => res.json())
          .then(
            (result) => {
              if (result !== "error") {
                setItemsBasket((prev: any) => [
                  ...prev,
                  {
                    id: result.id,
                    fiyat: result.fiyat,
                    koltuk: result.koltuk,
                    sira: result.sira,
                    seans_saat: result.seans_saat,
                  },
                ]);
                setLoadingBasket(false);
              } else {
                toast(
                  "Sepete eklenirken bir sorun oluştu. Lütfen bizimle iletişime geçin.",
                  {
                    duration: 40000,
                    position: "top-center",
                    style: { fontSize: 14 },
                    className: "",
                    icon: "❌",
                    iconTheme: {
                      primary: "#000",
                      secondary: "#fff",
                    },
                    ariaProps: {
                      role: "status",
                      "aria-live": "polite",
                    },
                  }
                );
              }
            },
            (error) => {
              console.error(error);
            }
          );
      });
    }
  };
  React.useEffect(refreshBasket, [basket_items]);
  const checkoutBasket = () => {
    setIsCheckoutLoading(true);
    setIsCheckoutDisabled(true);
    try {
      if (items_in_basket.length > 0) {
        localStorage.setItem("basket_tickets", "");
        localStorage.setItem("basket_tickets", JSON.stringify(items_in_basket));
        localStorage.setItem("event_detail", "");
        {
          event_item.seans &&
            event_item.seans.map((item: any) => {
              if (item.id == seans_id) {
                localStorage.setItem(
                  "event_detail",
                  JSON.stringify({
                    event_id: event_item?.id,
                    title: event_item?.title,
                    date: event_item?.date,
                    seans_id: seans_id,
                    seans_title: item.time,
                  })
                );
              }
            });
        }
      } else {
        toast("Sepetinizde satın alabileceğiniz herhangi bir ürün bulunmuyor", {
          duration: 5000,
          position: "top-center",
          style: { fontSize: 14 },
          className: "",
          icon: "❌",
          iconTheme: {
            primary: "#000",
            secondary: "#fff",
          },
          ariaProps: {
            role: "status",
            "aria-live": "polite",
          },
        });
      }
      setTimeout(() => {
        setIsCheckoutLoading(false);
        setIsCheckoutDisabled(false);
        navigate("/checkout");
      }, 500);
    } catch (e) {}
  };
  const addItemToBasket = () => {
    let is_have = false;
    let basket_count = 0;
    basket_items.map((item: any, index: number) => {
      basket_count++;
      if (item.id == koltuk_id) {
        is_have = true;
      }
    });
    if (basket_count <= 4) {
      if (!is_have) {
        if (basket_items.length > 0) {
          setBasketItems((prev: any) => [...prev, { id: koltuk_id }]);
        } else {
          setBasketItems([{ id: koltuk_id }]);
        }
        setIsButtonDisabled(true);
      } else {
        toast("Bu koltuk için seçim yaptınız.", {
          duration: 4000,
          position: "top-center",
          style: { fontSize: 14 },
          className: "",
          icon: "❌",
          iconTheme: {
            primary: "#000",
            secondary: "#fff",
          },
          ariaProps: {
            role: "status",
            "aria-live": "polite",
          },
        });
      }
    } else {
      toast("Tek seferde en fazla 5 adet bilet alabilirsiniz.", {
        duration: 4000,
        position: "top-center",
        style: { fontSize: 14 },
        className: "",
        icon: "❌",
        iconTheme: {
          primary: "#000",
          secondary: "#fff",
        },
        ariaProps: {
          role: "status",
          "aria-live": "polite",
        },
      });
    }
  };
  const fetchItems = async () => {
    setLoading(true);
    /* if (event_item == undefined) {
      return;
    } */
    fetch(config.apiUrl + config.getEventDetail + "/" + id)
      .then((res) => res.json())
      .then(
        (result) => {
          if (result !== "error") {
            setEvent(result);
            setLoading(false);
          } else {
            toast(
              "Etkinlik bilgileri yüklenemedi. Lütfen bizimle iletişime geçin.",
              {
                duration: 40000,
                position: "top-center",
                style: { fontSize: 14 },
                className: "",
                icon: "❌",
                iconTheme: {
                  primary: "#000",
                  secondary: "#fff",
                },
                ariaProps: {
                  role: "status",
                  "aria-live": "polite",
                },
              }
            );
          }
        },
        (error) => {
          console.error(error);
        }
      );
  };
  React.useEffect(() => {
    fetchItems();
  }, []);
  const siralariGetir = (value: string) => {
    setLoading(true);
    fetch(config.apiUrl + config.getEventQueue + "/" + value)
      .then((res) => res.json())
      .then(
        (result) => {
          if (result !== "error") {
            setKoltukSira(result);
            setLoading(false);
          } else {
            toast(
              "Koltuk sıraları getirilemedi. Lütfen bizimle iletişime geçin.",
              {
                duration: 40000,
                position: "top-center",
                style: { fontSize: 14 },
                className: "",
                icon: "❌",
                iconTheme: {
                  primary: "#000",
                  secondary: "#fff",
                },
                ariaProps: {
                  role: "status",
                  "aria-live": "polite",
                },
              }
            );
          }
        },
        (error) => {
          console.error(error);
        }
      );
  };
  const koltuklariGetir = (value: string) => {
    setLoading(true);
    fetch(
      `${config.apiUrl}${config.getEventChairs}/${event_item.id}/${seans_id}/${value}`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          if (result !== "error") {
            setChairs(result);
            setLoading(false);
          } else {
            toast(
              "Koltuk bilgileri alınamadı. Lütfen bizimle iletişime geçin.",
              {
                duration: 40000,
                position: "top-center",
                style: { fontSize: 14 },
                className: "",
                icon: "❌",
                iconTheme: {
                  primary: "#000",
                  secondary: "#fff",
                },
                ariaProps: {
                  role: "status",
                  "aria-live": "polite",
                },
              }
            );
          }
        },
        (error) => {
          console.error(error);
        }
      );
  };
  const handleChangeSeans = (event: SelectChangeEvent) => {
    setSeansId(event.target.value);
    siralariGetir(event.target.value);
  };
  const handleChangeSira = (event: SelectChangeEvent) => {
    setSiraId(event.target.value);
    koltuklariGetir(event.target.value);
  };
  const handleChangeChair = (event: SelectChangeEvent) => {
    setKoltukId(event.target.value);
    let is_have = false;
    basket_items.map((item: any, index: number) => {
      if (item.id == event.target.value) {
        is_have = true;
      }
    });
    setIsButtonDisabled(is_have);
  };
  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);
  let [isOpenCartModal, setIsOpenCartModal] = useState(false);
  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  function closeCartModal() {
    setIsOpenCartModal(false);
  }

  function openCartModal() {
    setIsOpenCartModal(true);
  }

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap space-y-6">
        {event_item?.title && (
          <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
            {event_item?.title}
          </h2>
        )}
        {!event_item?.title && (
          <Skeleton variant="rounded" width="100%" height={30} />
        )}
        <div className="flex flex-col md:flex-row gap-5 justify-between items-start md:items-center">
          <Badge
            name={
              event_item && event_item?.ucretsiz == "1"
                ? "Ücretsiz Etkinlik"
                : "Ücretli Etkinlik"
            }
          />
          {event_item?.adres && (
            <span>
              <i className="las la-map-marker-alt"></i>
              <span className="ml-1">{event_item?.adres}</span>
            </span>
          )}
          {!event_item?.adres && (
            <Skeleton variant="rounded" width="70%" height={25} />
          )}
          <div className="flow-root">
            <div className="flex column text-neutral-700 dark:text-neutral-300 text-sm -mx-0 md:-mx-3 -my-1.5">
              <Button
                variant="text"
                style={{ fontFamily: "var(--font-display)" }}
                onClick={() => {
                  navigator.share({
                    title: event_item?.title + " - " + config.appName,
                    url: config.eventUrl + "etkinlik/" + event_item?.id,
                  });
                }}
              >
                Paylaş
              </Button>
            </div>
          </div>
        </div>
        <div className="hidden md:block w-full border-b border-neutral-100 dark:border-neutral-700" />

        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-3 ">
            <i className=" las la-clock text-2xl "></i>
            <span>
              {event_item && event_item?.date}
              <span className="hidden sm:inline-block">
                &nbsp;ETKİNLİK BAŞLANGIÇ TARİHİ
              </span>
            </span>
          </div>
          {/* <div className="flex items-center space-x-3">
            <i className=" las la-couch text-2xl"></i>
            <span className=" ">Oturma Düzeni Mevcut</span>
          </div> */}
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Etkinlik Hakkında</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {event_item && event_item?.detail && (
          <div
            className="text-neutral-6000 dark:text-neutral-300"
            dangerouslySetInnerHTML={{
              __html: event_item && event_item?.detail,
            }}
          ></div>
        )}
        {!event_item?.title && (
          <Skeleton variant="rounded" width="100%" height={240} />
        )}
      </div>
    );
  };

  const renderSectionChairPlan = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Oturma Planı</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Salona ait oturma düzenine göz atın.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {event_item &&
          event_item.sit_plan_photo &&
          event_item.sit_plan_photo.length > 1 &&
          event_item.seans &&
          event_item.seans.length > 0 && (
            <div>
              <Button className="btn-default" onClick={openModalAmenities}>
                Etkinliğe Ait Oturma Planını Gör
              </Button>
            </div>
          )}
        {renderMotalAmenities()}
      </div>
    );
  };
  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Koltuk Seçimi </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Oturma planına göz atarak uygun olan koltuklardan birini seçin ve
            bilet alın.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="relative flex items-center justify-between xl:justify-start flex-wrap text-sm text-neutral-700 dark:text-neutral-300 bg-slate-50 dark:bg-slate-700 rounded-xl">
          {loading && (
            <div className="selector-progress">
              <CircularProgress size={24} />
            </div>
          )}
          {event_item && event_item.seans && event_item.seans.length > 0 && (
            <>
              <FormControl
                variant="filled"
                sx={{ m: 1, minWidth: 160 }}
                className="select-input m-w-sm"
              >
                <InputLabel id="demo-simple-select-filled-label">
                  Seans
                </InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={seans_id}
                  onChange={handleChangeSeans}
                  className="dark:bg-slate-500"
                  style={{}}
                >
                  {event_item.seans &&
                    event_item.seans.map((item: any) => (
                      <MenuItem key={`select-m-i-${item.id}`} value={item.id}>
                        {item.time}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl
                variant="filled"
                sx={{ m: 1, minWidth: 160 }}
                className="select-input m-w-sm"
              >
                <InputLabel id="demo-simple-select-filled-label">
                  Sıra / Bölüm
                </InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={sira_id}
                  onChange={handleChangeSira}
                  style={{}}
                >
                  {koltuk_siralar &&
                    koltuk_siralar.map((item: any) => (
                      <MenuItem key={`select-n-i-${item.id}`} value={item.id}>
                        {item.title}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <FormControl
                variant="filled"
                sx={{ m: 1, minWidth: 160 }}
                className="select-input m-w-sm"
              >
                <InputLabel id="demo-simple-select-filled-label">
                  Koltuk
                </InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={koltuk_id}
                  onChange={handleChangeChair}
                  style={{}}
                  disabled={chairs.length < 1}
                >
                  {chairs &&
                    chairs.map((item: any, index: number) => {
                      let is_disabled = false;
                      basket_items.map((subitem: any, subindex: number) => {
                        if (subitem.id === item.id) {
                          is_disabled = true;
                        }
                      });
                      if (item.durum == 1) {
                        is_disabled = true;
                      }
                      return (
                        <MenuItem
                          key={`select-d-i-${item.id}`}
                          value={item.id}
                          disabled={is_disabled}
                        >
                          {item.title} - {item.fiyat} TL
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
              <FormControl
                variant="filled"
                sx={{ m: 1, minWidth: 160 }}
                className="m-w-sm"
              >
                <Button
                  variant="contained"
                  disabled={is_button_disabled}
                  onClick={() => {
                    addItemToBasket();
                  }}
                >
                  Sepete Ekle
                </Button>
              </FormControl>
            </>
          )}
          {event_item && event_item.seans && event_item.seans.length < 1 && (
            <Alert severity="info" style={{ width: "100%" }}>
              Bu etkinliğe ait oturma düzeni bulunmuyor.
            </Alert>
          )}
        </div>
      </div>
    );
  };

  const renderMotalAmenities = () => {
    return (
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full max-w-4xl">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-50">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Etkinlik Salonu Oturma Düzeni
                    </h3>
                    <span className="absolute right-3 top-2">
                      <IconButton
                        aria-label="delete"
                        size="large"
                        onClick={closeModalAmenities}
                        style={{color:localStorage.theme === "light" ? "#000":"#fff"}}
                      >
                        <XMarkIcon className="w-5 h-5" />
                      </IconButton>
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    {event_item && <img src={event_item?.sit_plan_photo} />}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };
  const renderCartModal = () => {
    return (
      <Transition appear show={isOpenCartModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeCartModal}
        >
          <div className="min-h-full px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-full align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full max-w-4xl">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900 dark:text-white"
                      id="headlessui-dialog-title-70"
                    >
                      Sepetiniz
                    </h3>
                    <span className="absolute right-3 top-2">
                      <IconButton
                        aria-label="delete"
                        size="large"
                        onClick={closeCartModal}
                        className="text-black dark:text-white"
                      >
                        <XMarkIcon className="w-5 h-5" />
                      </IconButton>
                    </span>
                  </div>
                  <div className="px-0 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    {!items_in_basket && items_in_basket.length > 0 && (
                      <Alert severity="error">Henüz bilet eklemediniz</Alert>
                    )}
                    {loadingBasket && (
                      <Box
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <CircularProgress color="info" size={24} />
                      </Box>
                    )}
                    <div className="border-t border-gray-200">
                      <dl>
                        {items_in_basket &&
                          items_in_basket
                            .sort((a: any, b: any) =>
                              a.koltuk > b.koltuk ? 1 : -1
                            )
                            .map((item: any, index: number) => {
                              return (
                                <div
                                  className="hover:bg-slate-100 bg-slate-50 dark:bg-slate-800 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                                  key={`koltuk-${index}`}
                                >
                                  <dt className="text-sm font-medium text-gray-500 dark:text-white">
                                    {item.sira}
                                    {item.seans_saat} seansı - {item.koltuk}{" "}
                                    no'lu koltuk
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 dark:text-white sm:col-span-2 sm:mt-0 flex justify-between items-center">
                                    {item.fiyat} TL
                                    <IconButton
                                      color="primary"
                                      onClick={() => {
                                        handleDeleteBasketItem(item.id);
                                      }}
                                    >
                                      <TrashIcon
                                        className="w-4 h-4"
                                        aria-hidden="true"
                                      />
                                    </IconButton>
                                  </dd>
                                </div>
                              );
                            })}
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };
  const renderSidebar = () => {
    let price = 0;
    return (
      <>
        {renderCartModal()}
        {items_in_basket &&
          items_in_basket
            .sort((a: any, b: any) => (a.koltuk > b.koltuk ? 1 : -1))
            .map((item: any, index: number) => {
              price += parseFloat(item.fiyat);
            })}
        <div className="block lg:hidden fixed bottom-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-6000 z-20">
          <div className="container flex items-center justify-between">
            <div className="">
              {loadingBasket && (
                <>
                  <CircularProgress color="info" size={24} />
                </>
              )}
              {!loadingBasket && (
                <span
                  className="block text-xl font-semibold"
                  onClick={() => {
                    openCartModal();
                  }}
                >
                  {price.toFixed(2) || ""} TL
                  <span className="ml-1 text-sm font-normal text-neutral-500 dark:text-neutral-400">
                    /Sepeti Görüntüle
                  </span>
                </span>
              )}
            </div>
            {is_checkout_loading && (
              <CircularProgress
                color="info"
                style={{ color: "#fff" }}
                size={24}
              />
            )}
            {!is_checkout_loading && (
              <ButtonPrimary
                sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"
                onClick={checkoutBasket}
              >
                Satın Al
              </ButtonPrimary>
            )}
          </div>
        </div>
        
        {!event_item?.rezervasyonsuz && (
          <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
            <div className="sticky top-28">
              <div className="listingSectionSidebar__wrap shadow-xl">
                <h3 className="text-2xl font-semibold">Biletler</h3>
                {loadingBasket && (
                  <div className="relative">
                    <div className="selector-progress">
                      <CircularProgress size={36} />
                    </div>
                  </div>
                )}
                {!items_in_basket && items_in_basket.length > 0 && (
                  <Alert severity="error">Henüz bilet eklemediniz</Alert>
                )}
                <div className="flex flex-col space-y-4 ">
                  {items_in_basket &&
                    items_in_basket
                      .sort((a: any, b: any) => (a.koltuk > b.koltuk ? 1 : -1))
                      .map((item: any, index: number) => {
                        return (
                          <div
                            className="flex items-center justify-between text-neutral-6000 dark:text-neutral-300"
                            key={`basket-item-${index}`}
                          >
                            <span>
                              {item.seans_saat} seansı - {item.sira}
                              {item.koltuk} no'lu koltuk
                            </span>
                            <span>{item.fiyat} TL</span>
                            <span>
                              <IconButton
                                color="primary"
                                onClick={() => {
                                  handleDeleteBasketItem(item.id);
                                }}
                              >
                                <TrashIcon
                                  className="w-4 h-4"
                                  aria-hidden="true"
                                />
                              </IconButton>
                            </span>
                          </div>
                        );
                      })}
                  <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                  <div className="flex justify-between font-semibold">
                    <span>Toplam</span>
                    <span>{price.toFixed(2)} TL</span>
                  </div>
                  {event_item && event_item.ucretsiz == "1" && (
                    <Alert severity="info" style={{ width: "100%" }}>
                      Bu etkinliğe katılım ücretsizdir.
                    </Alert>
                  )}
                  <div className="flex justify-between font-semibold">
                    <Button
                      variant="contained"
                      disabled={
                        is_checkout_disabled || items_in_basket.length === 0
                      }
                      fullWidth
                      onClick={() => {
                        checkoutBasket();
                      }}
                      style={{color:localStorage.theme === "light" ? "#fff":"#fff"}}
                    >
                      {is_checkout_loading && (
                        <CircularProgress
                          color="info"
                          style={{ color: "#fff" }}
                          size={28}
                        />
                      )}
                      {!is_checkout_loading && <>Satın Al</>}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };
  return (
    <div
      className={`EventsPage nc-EventDetailPage ${className}`}
      data-nc-id="EventDetailPage"
    >
      {/* <Helmet>
        <title>{event_item?.title} - Lüleburgaz Belediyesi Etkinlik Hizmeti</title>
      </Helmet> */}
      <>
        <Toaster />
        <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
          {event_item?.photo && (
            <div className="relative mt-4">
              <div
                className="col-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                onClick={() => handleOpenModal(0)}
              >
                <NcImage
                  containerClassName="inset-0"
                  className="object-cover w-full h-half rounded-md event-detail-img sm:rounded-xl"
                  src={`https://luleburgaz.bel.tr/upload/etkinlikler/${event_item?.photo}`}
                />
                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
              </div>
              <div
                className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
                onClick={() => handleOpenModal(0)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                  />
                </svg>

                <span className="ml-2 text-neutral-800 text-sm font-medium">
                  Tüm fotoğrafları göster
                </span>
              </div>
            </div>
          )}
          {!event_item?.photo && (
            <Skeleton
              variant="rounded"
              width="100%"
              height={600}
              style={{ borderRadius: 16, marginTop: 16 }}
            />
          )}
        </header>
        {event_item && event_item.photos && (
          <ModalPhotos
            imgs={event_item?.photos}
            isOpen={isOpen}
            onClose={handleCloseModal}
            initFocus={openFocusIndex}
            uniqueClassName="nc-EventDetailPage-modalPhotos"
          />
        )}
      </>

      {/* MAIn */}
      <main className="container relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className={!event_item?.rezervasyonsuz ? "w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10":"w-full lg:w-5/5 xl:w-3/3 space-y-8 lg:space-y-10 lg:pr-10"}>
          {renderSection1()}
          {renderSection2()}
          {event_item &&
            event_item.sit_plan_photo &&
            event_item.sit_plan_photo.length > 1 &&
            event_item.seans &&
            event_item.seans.length > 0 &&
            renderSectionChairPlan()}
          {!event_item?.rezervasyonsuz && renderSection3()}
        </div>

        {/* SIDEBAR */}
        {renderSidebar()}
      </main>

      {/* {!isPreviewMode && renderMobileFooter()} */}

      {!isPreviewMode && (
        <div className="container py-24 lg:py-24">
          {/* SECTION 1 */}
          <div className="relative py-16">
            <BackgroundSection />
            {event_item && event_item.category_id && (
              <SectionSliderNewCategories
                heading="Diğer Etkinliklerimiz"
                subHeading="İlginizi çekebilecek diğer etkinliklere gözatın"
                itemPerRow={5}
                sliderStyle="style2"
                uniqueClassName={"EventDetailPage1"}
                category_id={event_item && event_item?.category_id}
              />
            )}
          </div>

          {/* <SectionSubscribe2 className="pt-24 lg:pt-32" /> */}
        </div>
      )}
    </div>
  );
};

export default EventDetailPage;

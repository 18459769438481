import React from "react";
import NavigationItem, { NavItemType } from "./NavigationItem";
import { NAVIGATION_DEMO } from "data/navigation";
import { Skeleton } from "@mui/material";
import { config } from "config";

function Navigation() {
  const [categories, setCategories] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(true);
  const fetchCategories = async () => {
    setLoading(true);
    fetch(config.apiUrl + config.getCategoriesUrl)
      .then((res) => res.json())
      .then(
        (result) => {
          setCategories(result);
          setLoading(false);
        },
        (error) => {}
      );
  };
  React.useEffect(() => {
    fetchCategories();
  }, []);
  const navigations: NavItemType[] = [
    {
      id: "100595",
      href: "/",
      name: "Anasayfa",
      isNew: true,
    },
    {
      id: "100596",
      href: "/etkinlik-kategorileri",
      name: "Kategoriler",
      type: "dropdown",
      isNew:true,
      children: categories
    },
  ];
  return (
    <ul className="nc-Navigation hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative">
      {!loading && categories && categories.length > 0 &&
        navigations.map((item) => (
          <NavigationItem key={item.id} menuItem={item} />
        ))}
      {loading && <Skeleton variant="rectangular" width={600} height={40} />}
    </ul>
  );
}

export default Navigation;

import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
const CategoriesPage = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  return (
    <div className="container py-24">
      <SectionGridCategoryBox />
    </div>
  )
};

export default CategoriesPage;

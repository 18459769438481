import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  /* {
    id: "5",
    title: "Getting started",
    menus: [
      { href: "#", label: "Installation" },
      { href: "#", label: "Release Notes" },
      { href: "#", label: "Upgrade Guide" },
      { href: "#", label: "Browser Support" },
      { href: "#", label: "Editor Support" },
    ],
  }, */
  {
    id: "1",
    title: "KATEGORİLER",
    menus: [
      { href: "/etkinlik-kategori/1", label: "Tiyatro" },
      { href: "/etkinlik-kategori/2", label: "Eğitim" },
      { href: "/etkinlik-kategori/3", label: "Konser" },
      { href: "/etkinlik-kategori/4", label: "Etkinlik" },
      { href: "/etkinlik-kategori/5", label: "Festival" },
      { href: "/etkinlik-kategori/6", label: "Söyleşi" },
      { href: "/etkinlik-kategori/7", label: "Çocuk Tiyatrosu" },
      { href: "/etkinlik-kategori/99", label: "Diğer" },
    ],
  },
  {
    id: "2",
    title: "POLİTİKALAR & BİLDİRİMLER",
    menus: [
      { href: "/kvkk", label: "Kişisel Verilerin Korunması Hakkında" },
      {
        href: "/satis-sozlesmesi",
        label: "Satış Politikaları ve Satış Sözleşmesi",
      },
      {
        href: "/gizlilik-beyani",
        label: "Gizlilik Beyanı ve Kişisel Veriler Politikası",
      },
    ],
  },
  {
    id: "4",
    title: "BİZE ULAŞIN",
    menus: [
      {
        href: "mailto:info@luleburgaz.bel.tr",
        label: "E-Mail: info@luleburgaz.bel.tr",
      },
      { href: "tel:02884171012", label: "Telefon: 02884171012" },
      { href: "tel:02884171073", label: "Telefon: 02884171073" },
      { href: "#!", label: "Fax: 0288 417 4779" },
    ],
  },
];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative py-24 lg:py-28 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-4 lg:gap-x-10 ">
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">
            <Logo />
          </div>
          <div className="col-span-2 flex flex-col items-start md:col-span-3">
            <span className="mb-3 text-xs">
              Özerler Mah, Hal Sok No:1 Lüleburgaz Belediyesi
            </span>
            {/* <SocialsList1 className="flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start" /> */}
          </div>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
      </div>
      <div className="container flex items-center justify-between mt-10">
        <a href="https://macroturk.com" target="_blank">
          <img src="/macroturk.svg" style={{ maxHeight: 15 }} alt="Macroturk Yazılım"/>
        </a>
        <img src="/troy.svg" style={{ maxHeight: 35 }} alt="troy"/>
      </div>
    </div>
  );
};

export default Footer;

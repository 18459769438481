import React from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CommonLayout from "./CommonLayout";

const SatisPage = () => {
  return (
    <div>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">
          Satış Politikaları ve Satış Sözleşmesi
          </h2>
          <h6 className="text-xs font-semibold text-slate-500">
            (Son güncelleme: 8 Şubat 2023)
          </h6>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="w-full">
            <span className="text-neutral-700 dark:text-neutral-300 block">
              <>
                <p className="text-justify">
                  6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında
                  Kanun’un (“Kanun”) 3 üncü maddesi ve diğer mevzuatın ilgili
                  hükümleri uyarınca, Lüleburgaz Belediyesi Bilet Dağıtım Basım
                  ve Tic. A.Ş (“Lüleburgaz Belediyesi”) ile ilgili tanıtıcı
                  bilgilerin tümü, sitemizin “Hakkımızda” başlıklı bölümünde ve
                  ayrıca Ön Bilgilendirme Metninde güncel şekilde yer
                  almaktadır. Lüleburgaz Belediyesi olarak hedefimiz; katılmayı
                  düşündüğünüz etkinliğin biletini en kısa sürede, en kolay
                  şekilde satın almanızı sağlamaktır. Aşağıdaki Satış
                  Politikamızı ve Satış Sözleşmemizi, sizi memnun kılacak ve
                  Lüleburgaz Belediyesi.com'daki satın alım süreci konusunda
                  sizi bilgilendirecek şekilde tasarladık. Belirtiriz ki,
                  internet sitesinden yapılan satışlar, mesafeli satış
                  düzenlemelerine bağlı olup, Taraflar arasındaki sözleşme
                  şartları mesafeli satış sözleşmesindeki kurallar dahilinde
                  belirlenmektedir. Şirketimiz, sözleşmenin kurulmasından önce,
                  satış sözleşmesi hükümlerini ve de ön bilgilendirme metnini
                  mevzuat hükümleri doğrultusunda sizler ile paylaştığından,
                  siparişinizi onaylamadan ve bilet satın almadan önce Satış
                  Sözleşmesini okumanızı tavsiye ederiz. Satış Politikası ve
                  Sözleşmemiz, Kullanım Koşullarımızın da bir bölümünü
                  oluşturur. Siparişinizi onaylayarak ve/veya web sitemizden
                  bilet alım işlemlerini gerçekleştirerek, Ön Bilgilendirme
                  Metnini, Kullanım Koşullarını, Satış Politikası ve Sözleşme
                  hükümlerini ve Gizlilik Beyanını okuduğunuzu, anladığınızı ve
                  içeriklerini aynen kabul etmiş sayılacağınızı belirtir, satış
                  politikası ve/veya sözleşme hükümlerinin aksine bir
                  talebinizin ya da şikayetinizin-çekincenizin olması durumunda,
                  herhangi bir bilet alım işlemi gerçekleştirmeden ve
                  siparişinizi onaylamadan önce, Yardım sayfasını ziyaret
                  etmenizi ve/veya bizimle sitemizde belirtilen iletişim
                  numaralarından iletişime geçmenizi öneririz. Sitemizden bilet
                  satın alarak ve siparişinizi onaylayarak, tarafınıza önceden
                  iletilen işbu Sözleşme hükümlerine bağlı olduğunuzu kabul
                  etmiş sayılacağınızı önemle vurgularız. Dolayısı ile
                  sitemizden bilet satın aldığınızda, etkinlik biletleri ile
                  ilgili tüm sürece, prosedürlere ve işlemlere aşağıda yer alan
                  Sözleşme hükümlerinin aynen uygulanacağını önemle belirtiriz.
                </p>
                <p className="text-justify">
                  Bilet satın alma işlemi ayrıca aşağıdaki hükümlere tabidir:
                  (i) etkinlik için uygulanacağı açıklanan ve duyurulan özel
                  hüküm ve koşullara, (ii) Etkinlik organizatörünün
                  (organizatörlerinin) belirlediği ve açıkladığı ve/veya
                  etkinliğin açıklanan diğer tüm hüküm ve koşullarına; ve (iii)
                  etkinlik organizatörü tarafından açıklanmış ise etkinlik mekan
                  hüküm ve koşullarına (giriş koşulları dahil). Dolayısı ile,
                  bilet satın alma işlemini yapmak suretiyle, etkinlik için
                  belirlenen ve duyurulan tüm hüküm ve koşullara ve Sözleşme
                  hükümlerine aynen riayet edeceğinizi kabul etmiş olmaktasınız.
                  Şayet, etkinliğin belirlenen ve açıklanan hüküm ve
                  koşullarından herhangi birini kabul etmiyorsanız veya bu
                  koşullardan herhangi birine riayet edemiyorsanız ve/veya
                  edemeyeceksiniz, bilet satın alma işlemini yapmamalısınız.
                  Aksi durumda, bilet satın almış olsanız dahi etkinlik mekanına
                  ve/veya etkinliğe alınmamanızdan ya da etkinlik mekanından
                  çıkarılmanızdan dolayı Şirketimizin herhangi bir sorumluluğu
                  olmayacağını, bilet bedeli de dahil olmak üzere Şirketimizin
                  herhangi bir ödeme yükümü oluşmayacağını önemle belirtiriz.
                  Ayrıca, Ön Bilgilendirme Metni ile de önceden
                  bilgilendirildiğiniz gibi, siparişinizi onaylayarak; bileti
                  satın almadan önce; a) firmamız ile ilgili gerekli tüm
                  bilgileri ve iletişim adreslerini b) satın aldığınız bilete ve
                  hizmete ilişkin tüm özellikleri ve ayrıca tüm vergiler dahil
                  toplam bilet/hizmet fiyatını ve varsa tüm ek masrafları c)
                  Ödeme, teslimat, ifaya ilişkin bilgileri ve şikayetlere
                  ilişkin sözleşmede düzenlenen çözüm yöntemlerini d) uyuşmazlık
                  konusunda başvurularınızı Tüketici Mahkemesine veya Tüketici
                  Hakem Heyetine yapabileceğinize dair sözleşmesel bilgileri e)
                  Mesafeli Sözleşmeler Yönetmeliği’nin 15 (g) maddesine
                  istinaden cayma hakkınızın olmadığını ve neticeten sözleşmede
                  düzenlenen tüm hükümleri bildiğinizi yazılı olarak kabul,
                  beyan ve taahhüt etmektesiniz. Siparişlerinizi onaylamadan ve
                  bileti satın almadan önce, mevzuat kapsamında gerekli ön
                  bilgilendirme yükümünün yerine getirilerek sizlerin
                  bilgilendirildiğini, sizlerden onayınız kapsamında, yazılı
                  şekilde ön bilgilendirme onayı alındığını ve ön bilgilendirme
                  onayının işbu Sözleşmenin ayrılmaz bir parçası olduğunu da
                  ayrıca ve önemle vurgularız. İlaveten, işbu sözleşme ve ön
                  onayınız ile “bilet satın alma siparişinizin onaylanması
                  durumunda ödeme yükümlülüğü altına gireceğiniz” konusunda açık
                  ve anlaşılır şekilde bilgilendirildiğinizi de önemle bilmenizi
                  isteriz.
                </p>
                <p className="text-justify">
                  <strong>
                    Etkinlik Bileti Alım/ Satımı ile İlgili Şart ve Koşullar/
                    Sözleşme Hükümleri:
                  </strong>
                </p>
                <p className="text-justify">
                  1. Lüleburgaz Belediyesi, yetkili bilet satış acentesi olarak,
                  biletini satın aldığınız etkinliği düzenleyen “organizatör,
                  etkinlik mekanı, kulüp veya tiyatroların” (Bundan böyle her
                  biri “Etkinlik Organizatörü” diye anılacaktır) etkinlik
                  biletlerinin satış ve dağıtımına aracılık etmekte olup, bilet
                  başına hizmet bedeli alır, ancak, etkinlik içeriğini, bilet
                  fiyatlarını veya oturma yerlerini belirleme konusunda hak ve
                  yetkiye sahip değildir. Lüleburgaz Belediyesi’in etkinlikler
                  kapsamında ve/veya etkinliklerin içeriğine dair hiçbir hak ve
                  yetkisi ve dolayısı ile sorumluluğu bulunmamaktadır.
                  Şirketimiz, etkinlik organizatörleri adına ya da hesabına
                  hareket etmemekte, organizatörler ile her ne nam adı altında
                  olursa olsun herhangi bir bayilik, distribütörlük,
                  temsilcilik, alt bayilik vs kapsamında da herhangi bir
                  hukuki/ticari ilişkisi bulunmamaktadır. Bileti satın alan
                  kişi, satın aldığı bileti hiçbir ticari amaç gütmeksizin,
                  münhasıran şahsi kullanımı için satın aldığını, Etkinlik
                  Organizatörü veya temsilcileri tarafından açıkça yazılı izin
                  verilmesi haricinde, bileti, hiçbir ticari iş veya başkaca bir
                  faaliyet için kullanmayacağını beyan ve taahhüt eder. Örnek
                  vermek gerekirse, satın alınan bilet, üçüncü kişilere her ne
                  şekilde olursa olsun satılamaz, biletin ilave ücretle ya da
                  başka şekilde satışı teklif edilemez; satın alınan bilet,
                  reklam, yarışma ve çekilişler de dahil olmak ancak bunlarla
                  sınırlı olmamak üzere promosyon veya diğer ticari amaçlarla ya
                  da yasa dışı şekilde kullanılamaz.
                </p>
                <p className="text-justify">
                  2. Lüleburgaz Belediyesi’in etkinliklere ve içeriklerine dair
                  hiçbir bilgisi, katkısı ya da dahli söz konusu olmamaktadır.
                  Etkinlikler için kişi başı maksimum bilet adedini ve fiyatları
                  Etkinlik Organizatör’ü belirler. Satın alınabilecek bilet
                  adedi, etkinlik sayfasında Lütfen Not Edin bölümünde ve satın
                  almanın ilk aşamasında belirtilir. Bu uygulamanın amacı haksız
                  bilet alımlarının önüne geçmektir. Bilet alımları, bazı
                  etkinliklerde kişi başına ve/veya kredi kartı başına,
                  bazılarında ise hane başına sınırlandırılabilir. Bu kuralın
                  herhangi bir şekilde ihlal edildiğini tespit edersek, bilet
                  alımı ister birden fazla kart kullanılarak, isterse de aynı
                  hanenin üyelerinin kart kullanımları ile gerçekleştirilsin,
                  Lüleburgaz Belediyesi, belirttiği adet sınırı üzerinde satın
                  alınan biletleri, önceden bildirimde bulunmaksızın tek taraflı
                  iptal etme hakkını saklı tutar. Otomatik vasıtaların kullanımı
                  suretiyle bilet satın alınması, kesinlikle yasaktır.
                </p>
                <p className="text-justify">
                  3. Biletler, genellikle internet, çağrı merkezi, perakende
                  satış noktaları gibi çeşitli dağıtım noktaları aracılığıyla
                  satılır. Tüm satış kanalları aynı biletleme havuzuna
                  eriştiğinden popüler etkinliklerde biletler hızlı bir şekilde
                  tükenebilir. Bazı durumlarda etkinlikten önce Etkinlik
                  Organizatörü’nün kararı ile ilave bilet satışa çıkarılabilir
                  ancak bu kapasite veya bilet mevcudiyeti konusunda herhangi
                  bir kontrol gücüne sahip bulunmamakla birlikte bu kapsamda
                  herhangi bir garanti de veremeyeceğimizi belirtiriz. Ayrıca,
                  bazı etkinliklerde etkinlik organizatörleri bilet fiyatlarını
                  sonradan güncelleyebilir, bu kapsamda bilet fiyatında artış ya
                  da indirim yapabilir, ek kampanyalar düzenleyebilir ya da
                  etkinliği ücretsiz bir etkinlik şekline de dönüştürebilir. Bu
                  gibi durumlarda Lüleburgaz Belediyesi’in herhangi bir
                  sorumluluğu bulunmadığını, etkinlik fiyatlarını etkinlik
                  organizatörünün belirlediğini yineleriz.
                </p>
                <p className="text-justify">
                  4. Satın aldığınız biletleriniz için teslimat şekli olarak
                  “kurye” seçeneğini seçerseniz, teslimatta sorun yaşamamak için
                  adres ve teslimatı alacak kişinin bilgilerinin doğru
                  olduğundan emin olunuz.Kurye bedeli, ön bilgilendirme
                  aşamasında ve satın alma işlemi sırasında “kurye/kargo bedeli”
                  açıklaması altında bilgilendirilmeniz kaydı ile kredi
                  kartınızdan tahsil edilir bu nedenle kuryeye/kargoya herhangi
                  bir ödeme yapmayınız. Teslimat bölgeleri haricinde bir adres
                  vermeniz durumunda teslimat yapılamamaktadır. Teslimat
                  sırasında Mail Order talep Formu veya Teslimat Formu
                  imzalatılabilir.
                </p>
                <p className="text-justify">
                  5. Biletlerinizin kontrol ve muhafaza yükümlüğü size aittir.
                  Biletlerin, doğrudan güneş ışığı veya ısı nedeniyle zarar
                  görerek kullanılmaz hale gelebileceğini unutmayın ve onları
                  güvenli bir yerde saklayın. Kaybolan, çalınan ya da herhangi
                  bir şekilde tahrip olan biletlerden dolayı Lüleburgaz
                  Belediyesi hiçbir şekilde sorumlu değildir. Bu durumda,
                  sizlere yeni bir bilet verilemeyeceğini ve/veya bilet değişimi
                  yapılamayacağını unutmayın.
                </p>
                <p className="text-justify">
                  6. Etkinlik Organizatörü çeşitli sebeplerle etkinlikleri iptal
                  edebilir veya tarihlerini değiştirebilir. Bu durumda
                  uygulanacak prosedürü organizatör belirler ve duyurur.
                  Etkinliğin iptal edilmesinden ve/veya etkinlik tarihinin
                  değiştirilmesinden Lüleburgaz Belediyesi sorumlu olmayıp,
                  Organizatör sorumludur. Bir etkinliğin iptal edilmesi veya
                  tarihinin değiştirilmesi durumunda Etkinlik Organizatörü'nden
                  gerekli yetkiyi almamız şartına bağlı olarak bilet hamillerini
                  söz konusu iptalden veya değişiklikten haberdar etmek için
                  makul gayreti göstereceğiz.Etkinliğin iptali ya da ertelenmesi
                  durumunda, aracı hizmet sağlayıcı olarak, bu durumu
                  öğrendiğimiz tarihten itibaren üç gün içinde yazılı olarak
                  veya kalıcı veri saklayıcısı ile sizleri bilgilendireceğiz.
                  Dolayısı ile satın aldığınız etkinlik bileti ile ilgili olası
                  güncellemelerden haberdar olabilmeniz için tarafımıza
                  bildirdiğiniz iletişim bilgilerinin güncel ve doğru olduğundan
                  ve de mesajlarınızın çöp kutusuna (SPAMA) taşınmadığından
                  lütfen emin olunuz.
                </p>
                <p className="text-justify">
                  7. Etkinliğin iptali ve/veya ertelenmesi haricinde, tüm bilet
                  satışları kesindir. Mesafeli Sözleşmeler Yönetmeliği’nin
                  "cayma hakkının istisnaları" başlığını taşıyan 15 inci
                  maddesinin (g) bendi uyarınca belirli bir tarihte veya dönemde
                  yapılması gereken, konaklama, eşya taşıma, araba kiralama,
                  yiyecek-içecek tedariki ve eğlence veya dinlenme amacıyla
                  yapılan boş zamanın değerlendirilmesine ilişkin sözleşmeler,
                  cayma hakkının istisnaları arasına giren sözleşmelerden
                  olduğundan, satın aldığınız etkinlik biletlerine dair cayma
                  hakkınız bulunmamaktadır. Satılan biletlerin herhangi bir
                  şekilde kaybolması, çalınması, hasar görmesi ya da imha olması
                  durumunda, bilet değiştirme/yeni bilet basma ya da para iadesi
                  yapılmaz. Etkinliğin iptal edilmesi haricinde, satılan
                  biletler, satış işlemi sonrasında hiçbir şekilde iptal
                  edilmez, değiştirilmez ve ücret iadesi yapılmaz. Ancak, bilet
                  sahibinin tarihi değiştirilmiş etkinliğe katılmasının mümkün
                  olmadığı hallerde iade prosedürlerine riayet edilmesi kaydı
                  ile bilet hamillerine ücret iadesi yapılabilir. Ücret iade
                  tutarları, satın alınan biletin üzerinde yazılı ücret iadesi
                  ile sınırlı olup, sair başkaca bir bedelin talebi mümkün
                  değildir. Bununla birlikte, etkinliğin iptali veya ertelenmesi
                  durumunda, Etkinlik Organizatörü geri ödeme şartları konusunda
                  bazı sınırlandırmalar getirebilme hakkına sahip olduğu gibi,
                  programda değişiklik yapma hakkına da sahiptir. Geri ödemeler
                  konusunda organizatörün belirlediği son iade talebi
                  tarihlerine ve diğer talimatlara uyulması ve tarafımıza
                  bildirdiğiniz iletişim bilgilerine bu konuda gönderdiğimiz
                  bilgilendirmelerin okunması/ kontrolü sizin
                  sorumluluğunuzdadır. Organizatör tarafından belirlenen iade
                  tarihleri dışında bilet ve/veya bedel iadesi yapılamaz. Bu
                  kapsamda Lüleburgaz Belediyesi hiçbir sorumluluk üstlenmez.
                  İptal edilen veya değişiklik yapılan etkinliklerle ilgili daha
                  fazla bilgiyi web sitemizde bulabilirsiniz.
                </p>
                <p className="text-justify">
                  8. Biletler ve/veya bilet satın alma hüküm ve koşulları,
                  Sözleşme hükümlerine ek olarak, mekanın ve etkinliğin
                  Organizatörünün belirlenen ve duyurulan kuralları uyarınca da
                  düzenlenir. Bu Kurallar ve Düzenlemelerden herhangi birinin
                  ihlali veya hasara, rahatsızlığa veya yaralanmaya/mevzuat
                  hükümlerinin ihlaline neden olabilecek kabul edilemez herhangi
                  bir davranış (kamu güvenliği nedenleri veya Covid 19'un
                  yayılmasıyla mücadele için alınan önlemlere uyulmaması dahil)
                  mekana veya Etkinlik organizatörüne sizi mekandan çıkarma
                  yetkisi verir ve para iadesi alma hakkınız olmaz. Bu durumda
                  Şirketimizin herhangi bir sorumluluğu olmayacağını, bilet
                  bedeli de dahil olmak üzere Şirketimizin herhangi bir ödeme
                  yükümü oluşmayacağını önemle belirtiriz.&nbsp;&nbsp; Mekanda,
                  kamu güvenliğini sağlamak amacıyla zaman zaman güvenlik
                  araması yapılabilir. Ayrıca, bazı organizatörler, ilgili
                  mevzuat hükümlerinin tatbiki amacıyla, güvenlik gerekçesi ile
                  ya da başkaca nedenlere istinaden, muhtelif kişisel bilgileri
                  bulunmayan müşterilere bilet satışı yapılmasını reddetme
                  hakkına sahiptir. Zira, etkinlik mekanına girecek kişilerin
                  kimlik bilgilerinin etkinlik organizatöründe bulunması, başta
                  güvenliğin sağlanması amacıyla bazı etkinlik organizatörleri
                  için gerekli bir bilgi mahiyetinde olabilmektedir. Bu gibi
                  durumlar, ilgili etkinlik bileti satışından önce
                  bilgilendirilir. Dolayısı ile bu gibi durumlarda kişisel
                  bilgilerinizin etkinlik organizatörü ile paylaşılmasını
                  istemez iseniz, etkinlik organizatörünün size bilet satışı
                  yapılmasını reddetme hakkı bulunduğundan, siparişi
                  tamamlamadan ve bileti satın almadan önce, işbu sözleşmenin
                  ayrılmaz bir parçası olan “Gizlilik Beyanını” ve kişisel
                  verilerle ilgili açıklamaları dikkatlice okumanız
                  gerekmektedir. Bu gibi özel durumlarda, Kişisel bilgilerinizi
                  etkinlik organizatörü ile paylaşmak istemeyişiniz nedeni ile,
                  size bilet satışı yapılamayacak olması durumunda, bu durumdan
                  Lüleburgaz Belediyesi sorumlu olmayacaktır.
                </p>
                <p className="text-justify">
                  9. Etkinlik mekanına giriş ve çıkışlar, Etkinlik
                  Organizatörü'nün ve etkinlik mekanının belirlediği kural ve
                  düzenlemelere bağlı olarak yapılır. Genel olarak bilet
                  kontrolünden sonra dışarı çıkarsanız yeniden etkinlik mekanına
                  giriş yapamazsınız. Etkinliğe geç kalırsanız, Etkinlik
                  Organizatörü'nün belirlediği kural ve düzenlemelere bağlı
                  olarak içeri hiç alınmayabilir ya da etkinlik mekanına geç
                  alınabilirsiniz ve bu kurallar organizatöre göre farklılık
                  gösterebilir. Geç kalanların içeriye alınması konusunda
                  Lüleburgaz Belediyesi hiçbir garanti veremez ve hiçbir şekilde
                  sorumlu değildir. Keza, etkinlik mekanına girmek için Etkinlik
                  Organizatörü ve/veya etkinlik mekanı tarafından belirlenen ve
                  sizlere önceden duyurulan özel kural ve düzenlemeler var ise,
                  bu kurallara uyulmasının gerekli olduğunu, aksi durumda mekana
                  alınmayabileceğinizi ve bilet iadesi yapılmayacağını da lütfen
                  unutmayınız. Dolayısı ile her bir bilet satım aşamasında,
                  etkinlikle ilgili olarak varsa Lütfen Not Ediniz ile ilgili
                  kurallara da bakmak sureti ile etkinlik biletini satın alınız.
                  Lütfen Not Ediniz Bölümündeki şartları sağlayamayacaksınız,
                  siparişinizi onaylamayınız.
                </p>
                <p className="text-justify">
                  10. Fotoğraf makinesi, cep telefonu veya kayıt ekipmanlarının
                  kullanımı Etkinlik Organizatörünün belirlediği kural ve
                  düzenlemelere bağlıdır. Bu nedenle fotoğraf makinesi veya
                  diğer kayıt ekipmanlarının, lazer kalemi ve cep telefonlarının
                  etkinlik mekanına sokulması yasaklanabilir.
                </p>
                <p className="text-justify">
                  11. Etkinlik Organizatörü, engellilere kılavuzluk eden
                  köpekler haricinde, hayvanların etkinlik sahasına sokulmasını
                  yasaklayabilir.
                </p>
                <p className="text-justify">
                  12. Etkinlik Organizatörü, müşterilerin dışarıdan getirdiği
                  yiyecek ve içeceklerin mekana sokulmasını yasaklayabilir.
                  Etkinlik mekanında satılan yiyecek ve içeceklerden Lüleburgaz
                  Belediyesi sorumlu değildir.
                </p>
                <p className="text-justify">
                  13. Etkinlik mekanı, Etkinlik Organizatörü ve Lüleburgaz
                  Belediyesi, müşterilerin şahsi eşyalarıyla ilgili hiçbir
                  sorumluluğu üstlenmez. Bu yüzden etkinlik mekanında tüm
                  eşyalarınızdan bizatihi sorumlu olduğunuzu lütfen unutmayınız.
                </p>
                <p className="text-justify">
                  14. Bilet hamili, sadece bilet üzerinde yazılı kıymeti haiz
                  bir adet yer üzerinde hak sahibi olup, etkinlik mekanı veya
                  Etkinlik Organizatörü, bilet sahibine, bilet üzerinde
                  belirtilen yerden farklı bir yer verme hakkını saklı tutar. Bu
                  durumdan yalnızca Organizatör sorumlu olup, Lüleburgaz
                  Belediyesi sorumlu değildir.
                </p>
                <p className="text-justify">
                  15. Lüleburgaz Belediyesi, bilet üzerinde yazılı bedel ve
                  ilgili hizmet bedeli dışında, biletlere/ bilet satışlarına
                  ve/veya etkinliklere/ etkinlik içeriklerine ilişkin olarak
                  başkaca hiçbir yükümlülük, taahhüt ve sorumluluk altında
                  değildir. Dolayısı ile herhangi bir gerekçe ile Lüleburgaz
                  Belediyesi’den bu tutarlar haricinde, yol gideri, konaklama
                  gideri, otopark-benzin-geçiş gideri vb her ne nam adı altında
                  olursa olsun başkaca herhangi bir bedel talep edemeyeceğinizi
                  önceden kabul ve taahhüt etmektesiniz. Siz, Lüleburgaz
                  Belediyesi’in sorumluluğunun bu madde ile sınırlı olduğunu ve
                  her ne nam adı altında olursa olsun, işbu maddedeki sorumluluk
                  limiti dışında, Lüleburgaz Belediyesi’i sorumlu kabul
                  etmeyeceğinizi önceden kabul, beyan ve taahhüt etmiş
                  sayılırsınız.
                </p>
                <p className="text-justify">
                  16. Bilet satın alma işlemiyle ilgili bilgi almak isterseniz,
                  işleminizi tamamlarken size verdiğimiz referans numarası ile
                  Müşteri Hizmetleri’ni arayabilirsiniz.
                </p>
                <p className="text-justify">
                  17. Web sitemiz Türkçe ve İngilizce olarak hazırlanmıştır.
                  Metinler arasında herhangi bir farklılık ve/veya çelişki
                  olması halinde Türkçe metin esas alınır.
                </p>
                <p className="text-justify">
                  18. Sözleşme kapsamındaki ticari elektronik iletiler, önceden
                  onaylarınız alınmak kaydıyla sizlere gönderilebilir. Sözleşme
                  kapsamında ticari elektronik ileti gönderilmesini istemez
                  iseniz, “elektronik ileti gönderilmesi” bölümündeki onay
                  işaretini kaldırmanız gerektiğini hatırlatırız. Ayrıca,
                  dilediğiniz zaman, hiçbir gerekçe belirtmeksizin ticari
                  elektronik iletileri almayı reddedebilme hakkına da
                  sahipsiniz. Buna karşın, kendinizle iletişime geçilmesi
                  amacıyla iletişim bilgilerini vermeniz hâlinde, temin edilen
                  hizmetlere ilişkin değişikliklere ve/veya kullanıma yönelik
                  ticari elektronik iletiler için ayrıca onay alınmayacağını da
                  belirtiriz. Ayrıca, hizmetin sağlanması sırasında, bu tür
                  haberleşmenin yapılacağına dair sizlerin bilgilendirilerek ve
                  size reddetme imkânı sağlanarak iletişim bilgilerinizin
                  edinilmiş olması hâlinde, önceden izniniz alınmaksızın aynı
                  veya benzer hizmetlerle ilgili pazarlama, tanıtım ve/veya
                  değişiklik hizmetleri için haberleşme yapılabilmesi de hukuken
                  mümkündür. Bununla birlikte, bu tür haberleşme yapılmasını
                  reddetme ve bu kapsamda verdiğiniz izinleri geri alma hakkınız
                  saklıdır ve bu talebinizin tarafımıza her türlü yolla
                  iletilmesi yeterlidir.
                </p>
                <p className="text-justify">
                  19. İşbu sözleşme metni, sözleşmenin kurulmasından sonra,
                  Lüleburgaz Belediyesi dataları tarafından saklanmakta olup, bu
                  sözleşmeye, revize tarihleri itibariyle sitemizin
                  “Politikalarımız” bölümünden kolayca erişebilirsiniz.
                  Dilerseniz, sözleşmenin çıktısını almak suretiyle, fiziki
                  kopyasına da kolayca erişebilirsiniz. Dolayısı ile sözleşme
                  hükümlerinin ve genel işlem şartlarının sizler tarafından
                  kolayca sağlanmasına ve saklanmasına imkan sağlandığını
                  bilmenizi isteriz. Sözleşmeye erişiminiz, sitemize erişimin
                  sağlanması ile doğru orantılı olacak şekilde devam edecektir.
                </p>
                <p className="text-justify">
                  20. Sitemizin kullanımına ve işbu sözleşmeye dair gizlilik
                  kuralları, “Politikalarımız” bölümünde “Gizlilik Beyanı”
                  kısmında detaylı şekilde belirtilmiştir. Bu kapsamda, Gizlilik
                  Beyanı hükümlerini dikkatlice okumanızı, incelemenizi ve
                  farklı talepleriniz var ise tarafımıza bildirmeniz
                  gerekeceğini bilgilerinize sunarız. Gizlilik Beyanı, işbu
                  Sözleşmenin eki ve ayrılmaz bir parçası olup, Sözleşme ile
                  birlikte aynen uygulanacak ve hüküm ifade edecektir. Dolayısı
                  ile işbu Sözleşmeyi ve/veya siparişi onaylayarak, Gizlilik
                  Beyanı hükümlerini de kabul etmiş sayılacağınızı önemle
                  belirtiriz.
                </p>
                <p className="text-justify">
                  21. Siparişin onaylanması aşamasında ve ödeme bilgilerinin
                  girilmesinden önce, ödeyeceğiniz toplam bedel de dâhil olmak
                  üzere, sözleşmenin tüm şartları sizler tarafından açıkça
                  görülmektedir. Ayrıca, satın aldığınız hizmete dair tüm
                  detaylar da siparişi onaylamadan önce tarafınıza bildirilmiş
                  olmaktadır. Dolayısı ile bileti satın alarak ve siparişi
                  onaylayarak, işbu sözleşme hükümlerini aynen kabul etmiş
                  sayılacağınızı ve Sözleşme hükümleri ile bağlı olacağınızı
                  yineler, siparişi onaylamadan önce tüm sözleşme hükümlerini
                  dikkatlice incelemeniz gerektiğini bir kez daha hatırlatırız.
                </p>
                <p className="text-justify">
                  22. Lüleburgaz Belediyesi, sizlerle ilgili elde edilen kişisel
                  verilerin saklanmasından ve güvenliğinden sorumludur. Ayrıca,
                  kişisel verilerinizin sizlerin onayı olmaksızın, üçüncü
                  kişilere iletilemeyeceği ve sizlere bildirilen amaçlar dışında
                  başka amaçlarla kullanılamayacağını da belirtiriz. Ayrıca,
                  kişisel verilerinizin muhafazasına ve kullanımına dair detaylı
                  bilgilerin tümüne, Sitemizin, “Politikalarımız” bölümünde yer
                  alan “Gizlilik Beyanı” ve “Kişisel Verilerin Korunması
                  Hakkında Bilgilendirme” bölümünden ulaşabileceğinizi belirtir,
                  Gizlilik Beyan’ının işbu Sözleşmenin eki ve ayrılmaz bir
                  parçası olduğunu yineleriz.
                </p>
                <p className="text-justify">
                  23. Bu şart ve koşulların tamamı Türk Hukukuna tabidir.
                  Lüleburgaz Belediyesi; biletinizi satın almadan önce sözleşme
                  hükümleri ve içeriği konusunda, tüm tüketicileri önceden
                  bilgilendirmiş ve ön bilgilendirme yükümünü yerine getirmiş
                  olup, bileti satın alarak ve siparişi onaylayarak, işbu
                  sözleşmenin düzenlemeleri ve satın aldığınız hizmet hakkında
                  tarafınıza ön bilgilendirme yapıldığını da kabul etmiş
                  sayılacağınızı ve bu maddenin yazılı delil vasfında ispat
                  vasıtası olduğunu da belirtiriz. İşbu Sözleşmeden ve/veya
                  hizmet ya da bilet satın alma işlemlerinden dolayı herhangi
                  bir ihtilafın doğması durumunda, uyuşmazlığı öncelikle sulhen
                  gidermeye çalışmak temel hedefimizdir. Buna karşın, sulh ile
                  çözülemeyen durumlarda, sitemizden bilet satın alan siz
                  tüketicilerin, olası sorunlarını ilgili mevzuatta yayımlanan
                  parasal sınırlar çerçevesinde Tüketici Hakem Heyetlerine
                  ve/veya Tüketici Mahkemelerine götürebileceğini bilmenizi
                  isteriz.
                </p>
                <p className="text-justify">&nbsp;</p>
                <p className="text-justify">&nbsp;</p>
                <p className="text-justify">
                  Ticaret Ünvanı : Lüleburgaz Belediyesi
                </p>
                <p className="text-justify">&nbsp;</p>
                <p className="text-justify">
                  İletişim Adresi : Özerler Mah, Hal Sok No:1 Lüleburgaz
                  Belediyesi - Lüleburgaz, Kırklareli
                </p>
                <p className="text-justify">&nbsp;</p>
                <p className="text-justify">
                  İletişim Telefonu : 0288 417 10 12
                </p>
                <p className="text-justify">
                  İletişim Telefonu : 0288 417 10 73
                </p>
                <p className="text-justify">
                  İletişim Faks Numarası : 0288 417 47 79
                </p>
              </>
            </span>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default SatisPage;

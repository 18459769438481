import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { Link } from "react-router-dom";
import { EventsType } from "data/types";
import moment from "moment";

export interface HomeEventCardProps {
  className?: string;
  data?: EventsType;
}

/* const DEMO_DATA = DEMO_STAY_LISTINGS[0]; */
const HomeEventCard: FC<HomeEventCardProps> = ({
  className = "",
  data = {
    id: 1,
    title: "",
    adres: "",
    date: "",
    photo: "",
  },
}) => {
  const { id, title, photo, date,adres } = data;
  const photos = [photo];
  const renderSliderGallery = () => {
    return (
      <div className="flex-shrink-0 p-3 w-full sm:w-64 ">
        <GallerySlider
          ratioClass="aspect-w-1 aspect-h-1"
          galleryImgs={photos}
          className="w-full h-full rounded-2xl overflow-hidden will-change-transform"
          uniqueID={`HomeEventCard_${id}`}
          href={`/etkinlik/${id}`}
        />
      </div>
    );
  };

  const renderTienIch = () => {
    return (
      <div className="inline-grid grid-cols-1 gap-2">
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <i className="las la-clock text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            Tarih: {moment(date).format('DD.MM.YYYY')}
          </span>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="flex-grow p-3 sm:pr-6 flex flex-col items-start">
        <div className="space-y-7 w-full">
          <div className="flex items-center space-x-2">
            <h2 className="text-lg font-medium capitalize">
              <span className="line-clamp-2">{title}</span>
            </h2>
          </div>
          <div className="flex items-center space-x-2">
            <div className="text-sm font-small">
              <span className="line-clamp-2">{adres}</span>
            </div>
          </div>
          {renderTienIch()}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-HomeEventCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="HomeEventCard"
    >
      <Link to={`/etkinlik/${id}`} className="absolute inset-0"></Link>
      <div className="h-full w-full flex flex-col sm:flex-row sm:items-center">
        {renderSliderGallery()}
        {renderContent()}
      </div>
    </div>
  );
};

export default HomeEventCard;

import Glide from "@glidejs/glide";
import React, { FC } from "react";
import { useEffect } from "react";
import useNcId from "hooks/useNcId";
import { config } from "config";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { CalendarIcon, MapPinIcon, StarIcon, TrashIcon } from "@heroicons/react/24/solid";
import Moment from 'react-moment';
import 'moment/dist/locale/tr';
import moment from "moment";
export interface HomeSliderProps {
  className?: string;
  uniqueClassName: string;
}

const HomeSlider: FC<HomeSliderProps> = ({
  className = "",
  uniqueClassName = "",
}) => {
  const UNIQUE_CLASS = `HomeSlider_glide_${uniqueClassName}` + useNcId();
  const [items, setItems] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(true);
  const fetchItems = async () => {
    setLoading(true);
    fetch(config.apiUrl + config.homeEventsUrl)
      .then((res) => res.json())
      .then(
        (result) => {
          setItems(result);
          setLoading(false);
          setTimeout(() => {
            if (document.querySelector(`.${UNIQUE_CLASS}`)) {
              setTimeout(() => {
                var glide = new Glide(`.${UNIQUE_CLASS}`, {
                  perView: 1,
                });
                glide.on("move.after", function () {
                  try {
                    let src = document
                      .querySelector(".glide__slide--active img")
                      ?.getAttribute("src") as string;
                    let morp = document.getElementById("glassMorphism");
                    morp?.setAttribute("src", src);
                  } catch (e) {}
                });
                glide.mount();
              }, 1000);
            }
          }, 1000);
        },
        (error) => {}
      );
  };
  useEffect(() => {
    fetchItems();
  }, []);
  moment.locale('tr');
  return (
    <div
      className={`nc-HomeSlider relative ${className} `}
      data-nc-id="HomeSlider"
    >
      {loading && (
        <Skeleton
          height="80vh"
          baseColor="#0c1222"
          highlightColor="#171e30"
          borderRadius={36}
        />
      )}
      {!loading && (
        <div className={`relative md:mb-16 w-full mx-auto ${UNIQUE_CLASS}`}>
          <div className="glide__track w-full" data-glide-el="track">
            <ul className="glide__slides ">
              {items.map((item) => (
                <li
                  key={item.id}
                  className="glide__slide flex flex-col items-center text-center"
                >
                  <span className="block text-2xl">
                    <Link to={`/etkinlik/${item.id}`}>
                      <img
                        className="inset-0 object-cover rounded-xl border border-slate-200 home-slider-img"
                        src={`https://luleburgaz.bel.tr/upload/etkinlikler/${item.photo}`}
                        alt="Lüleburgaz Yıldızları Kadın Akademisi - Prof. Dr. Türkan Saylan Salonu"
                      />
                    </Link>
                  </span>
                  <div className="absolute flex items-center bottom-0.5 w-screen hidden lg:block">
                    <div className="relative container flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-white">
                      <div
                        className="flex items-center gap-2 bg-slate-50 dark:bg-slate-700 rounded-xl px-5 py-2 w-1/3"
                        style={{ height: "80px" }}
                      >
                        <StarIcon className="w-6 h-6" aria-hidden="true" />
                        <div className="flex flex-col items-start">
                          <h2 className="text-lg font-semibold xs:text-sm">Etkinlik Adı</h2>
                          <h6 className="text-xs ">{item.title}</h6>
                        </div>
                      </div>
                      <div
                        className="flex items-center gap-2 bg-slate-50 dark:bg-slate-700 rounded-xl px-5 py-2 w-1/3"
                        style={{ height: "80px" }}
                      >
                        <MapPinIcon className="w-6 h-6" aria-hidden="true" />
                        <div className="flex flex-col items-start">
                          <h2 className="text-lg font-semibold ">Adres</h2>
                          <h6 className="text-xs text-left">{item.adres}</h6>
                        </div>
                      </div>
                      <div
                        className="flex items-center gap-2 bg-slate-50 dark:bg-slate-700 rounded-xl px-5 py-2 w-1/3"
                        style={{ height: "80px" }}
                      >
                        <CalendarIcon className="w-6 h-6" aria-hidden="true" />
                        <div className="flex flex-col items-start">
                          <h2 className="text-lg font-semibold">Tarih</h2>
                          <h6 className="text-xs ">{moment(item.date).format('DD.MM.YYYY')}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          {/* <div
            className="mt-10 glide__bullets flex items-center justify-center"
            data-glide-el="controls[nav]"
          >
            {items.map((item, index) => (
              <button
                key={item.id}
                className="glide__bullet w-2 h-2 rounded-full bg-neutral-300 transition-all mx-1 focus:outline-none hover:bg-black"
                data-glide-dir={`=${index}`}
              ></button>
            ))}
          </div> */}
          <div className="glide__arrows" data-glide-el="controls">
            <button
              className="glide__arrow glide__arrow--prev flex absolute top-0 left-0 z-30 justify-center items-center px-4 h-full cursor-pointer group focus:outline-none"
              data-glide-dir="<"
            >
              <span className="inline-flex justify-center items-center transition-all w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-slate-50 dark:bg-slate-400 group-hover:bg-white/50 dark:group-hover:bg-slate-500 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                <svg
                  className="w-5 h-5 text-dark sm:w-6 sm:h-6 dark:text-gray-800"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 19l-7-7 7-7"
                  ></path>
                </svg>
                <span className="hidden">Previous</span>
              </span>
            </button>
            <button
              className="glide__arrow glide__arrow--next flex absolute top-0 right-0 z-30 justify-center items-center px-4 h-full cursor-pointer group focus:outline-none"
              data-glide-dir=">"
            >
              <span className="inline-flex justify-center items-center transition-all w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-slate-50 dark:bg-slate-400 group-hover:bg-white/50 dark:group-hover:bg-slate-500 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                <svg
                  className="w-5 h-5 text-dark sm:w-6 sm:h-6 dark:text-gray-800"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5l7 7-7 7"
                  ></path>
                </svg>
                <span className="hidden">Next</span>
              </span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomeSlider;

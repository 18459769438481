/* import { I18n } from 'i18n-js'; */
export const config = {
    appName: 'Lüleburgaz Belediyesi Etkinlik Hizmeti',
    appUrl: 'https://luleburgaz.bel.tr/',
    eventUrl: 'https://etkinlik.luleburgaz.bel.tr/',
    apiUrl: 'https://luleburgaz.bel.tr/api/v1/events/',
    homeEventsUrl: `get-latest-events`,
    homeExploreEventsUrl: `get-explore-events`,
    getPayDetail: `get-pay-detail`,
    getCategoriesUrl: `get-categories`,
    getEventDetail: `get-event-detail`,
    getEventQueue: `get-event-queue`,
    getEventChairs: `get-event-chairs`,
    getChairDetail: `get-chair-detail`,
    getTicketDetail: `get-ticket-detail`,
    getSimiliarEvents: `get-similiar-events`,
    getEventsByCategory: `get-events-by-category`,
    getEventCategoryDetail: `get-event-category-detail`,
    cancelTicketUrl: `cancel-ticket`,
    checkoutPostUrl: `checkout-tickets`,
    checkOrderStatus: `check-order-status`,
    aes_key: 'B4Bzmktu4Exwb0StGTcujr3haf4902sT',
};
import React from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CommonLayout from "./CommonLayout";

const KVKKPage = () => {
  return (
    <div>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">
            KİŞİSEL VERİLERİN KORUNMASI HAKKINDA BİLGİLENDİRME
          </h2>
          <h6 className="text-xs font-semibold text-slate-500">
            (Son güncelleme: 8 Şubat 2023)
          </h6>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="w-full">
            <span className="text-neutral-700 dark:text-neutral-300 block">
              <>
                <p className="text-justify">
                  Lüleburgaz Belediyesi olarak, siz değerli müşterilerimizin
                  veri güvenliğini göz önünde bulundurarak, başta özel hayatın
                  gizliliği olmak üzere, kişisel verilerinizin korunması
                  amacıyla, kişisel verilerle ilgili düzenlenen "Kişisel
                  Verilerin Korunması Kanunu" hususunda sizi bilgilendirmek ve
                  bu kapsamda mevzuatta düzenlenen “Aydınlatma Yükümlülüğümüzü”
                  yerine getirmek istiyoruz.
                </p>
                <p className="text-justify">&nbsp;</p>
                <p className="text-justify">
                  Amacımız; kişisel verilerinizin alınma şekilleri, işlenme
                  amaçları, hukuki nedenleri ve haklarınız konularında sizi en
                  şeffaf şekilde bilgilendirmektir. Lüleburgaz Belediyesi’e
                  iletmiş olduğunuz her türlü kişisel veri (“Veri”) bakımından,
                  7 Nisan 2016 tarihinde yürürlüğe giren 6698 sayılı Kişisel
                  Verilerin Korunması Kanunu (“KVKK”) ve diğer ilgili mevzuat
                  hükümleri gereğince, Şirketimiz Lüleburgaz Belediyesi A.Ş.;
                  gerekmesi durumunda Veri Sorumlusu ve gerekmesi durumunda da
                  Veri İşleyen sıfatıyla, kişisel bilgileri; ilgili mevzuat
                  hükümlerinde açıklandığı kapsamda ve aşağıda açıklanan şart ve
                  sınırlara bağlı kalarak; elde edebilecek, kaydedebilecek,
                  depolayabilecek, muhafaza edebilecek, hizmetlerini devam
                  ettirebilmek amacıyla güncelleyebilecek, değiştirebilecek,
                  yeniden düzenleyebilecek, mevzuatın izin verdiği durumlarda ve
                  ölçüde üçüncü kişilere açıklayabilecek, devredebilecek,
                  aktarabilecek, paylaşabilecek, sınıflandırabilecek, anonim
                  hale getirebilecek ve kanunda sayılan diğer şekillerde
                  işleyebilecektir.
                </p>
                <p className="text-justify">&nbsp;</p>
                <p className="text-justify">
                  Veri Sorumlusu: İletişim bilgileri aşağıda aktarılan
                  Lüleburgaz Belediyesi
                </p>
                <p className="text-justify">&nbsp;</p>
                <p className="text-justify">
                  Kişisel verilerinizin işlenme amaçları ve hukuki sebepleri ;
                  Kimlik bilgileri, erişim/iletişim bilgileri, tüketici
                  alışkanlığına ilişkin bilgiler, sesli ve görüntülü
                  görüşmelerde veya kurulan sözleşmeler kapsamında gerçekleşen
                  işlemler sonucu edinilen siz müşterilerimizin kimliğini
                  belirli veya belirlenebilir hale getirmeye yarayan her türlü
                  bilgi ve belge, KVKK madde 3/d hükmüne istinaden kişisel veri
                  kapsamındadır.
                </p>
                <p className="text-justify">&nbsp;</p>
                <p className="text-justify">
                  Veri Kategorisi Veri Saklama Süresi (Mevzutta öngörülen
                  istisnai düzenlemeler ayrı ve saklı olduğu gibi, veri
                  sahiplerinin KVKK kapsamında verilerinin silinmesine-yok
                  edilmesine dair hakları da ayrı ve saklıdır)
                </p>
                <p className="text-justify">1 &nbsp; Kimlik &nbsp;Hukuki İlişki + 10 Yıl</p>
                <p className="text-justify">
                  2 &nbsp; İletişim Bilgisi &nbsp; &nbsp;Hukuki İlişki + 10 Yıl
                </p>
                <p className="text-justify">3 &nbsp; Lokasyon Bilgisi &nbsp; &nbsp;5 Yıl</p>
                <p className="text-justify">
                  4 &nbsp; Özlük Bilgisi &nbsp; İstihdamın sona ermesi + 10 yıl
                </p>
                <p className="text-justify">
                  5 &nbsp; Hukuki İşlem Bilgisi &nbsp; &nbsp;Hukuki İlişki + 10
                  Yıl
                </p>
                <p className="text-justify">
                  6 &nbsp; Müşteri İşlem Bilgisi &nbsp; Hukuki İlişki + 10 Yıl
                </p>
                <p className="text-justify">7 &nbsp; Fiziksel Mekan Güvenliği &nbsp; &nbsp;1 Yıl</p>
                <p className="text-justify">8 &nbsp; İşlem Güvenliği Bilgisi Hukuki İlişki + 10 Yıl</p>
                <p className="text-justify">9 &nbsp; Finans Bilgisi &nbsp;Hukuki İlişki + 10 Yıl</p>
                <p className="text-justify">10 &nbsp;Mesleki Deneyim Bilgisi İstihdam + 10 Yıl</p>
                <p className="text-justify">11 &nbsp;Pazarlama Bilgisi &nbsp; 1 Yıl</p>
                <p className="text-justify">12 &nbsp;Görsel ve İşitsel Kayıtlar &nbsp;1 Yıl</p>
                <p className="text-justify">
                  13 &nbsp;Dernek Üyeliği Bilgisi &nbsp;Hukuki İlişki + 10 Yıl
                </p>
                <p className="text-justify">
                  14 &nbsp;Vakıf Üyeliği Bilgisi &nbsp; Hukuki İlişki + 10 Yıl
                </p>
                <p className="text-justify">
                  15 &nbsp;Sağlık Bilgileri &nbsp; &nbsp;Hukuki İlişki + 10 Yıl
                </p>
                <p className="text-justify">
                  16 &nbsp;Ceza Mahkumiyeti ve Güvenlik Tedbirleri Hukuki İlişki
                  + 10 Yıl
                </p>
                <p className="text-justify">
                  17 &nbsp;Diğer Bilgiler-Çalışan, Çalışan Adayı ve Stajyer
                  Bilgisi &nbsp; &nbsp;Hukuki İlişki + 10 Yıl
                </p>
                <p className="text-justify">
                  18 &nbsp;Diğer Bilgiler-Talep ve Şikayet Bilgisi Hukuki İlişki
                  + 10 Yıl
                </p>
                <p className="text-justify">19 &nbsp;Diğer Bilgiler-Araç Bilgisi 5 Yıl</p>
                <p className="text-justify">
                  Kişisel verileriniz; tarafınızla akdedilen sözleşmelerin ve
                  taahhüt edilen hizmetleringereklerinin yerine getirilmesi,
                  etkinlik biletlerinin ve diğer hizmetlerin satışının yapılması
                  ve tercih ettiğiniz teslimat yöntemine bağlı olarak sizlere ya
                  da bildirdiğiniz kişilere teslim edilebilmesi-ulaştırılması,
                  ödemelerinizin gerçekleşebilmesi, gerekmesi durumunda iadesi,
                  e-faturalama, e-arşiv faturalama ve gerekmesi durumunda iade
                  ve değişiklik işlemlerinin yapılabilmesi, satın aldığınız
                  etkinlikler ya da katıldığınız kampanya/çekilişler kapsamında
                  tercih ettiğiniz iletişim kanalı üzerinden size bilgi-iletişim
                  paylaşımı yapılması, işlem bilgilerinin tespiti, talebinize
                  bağlı olarak Lüleburgaz Belediyesi üyelik kaydı
                  oluşturabilmeniz ve kaydınızın muhafazası, iletişim izni
                  vermiş iseniz tanıtım/bilgilendirme, pazarlama ve kampanya
                  faaliyetlerinin yapılması, etkinlik bülteni gönderilmesi, veri
                  analizi-araştırma yapılabilmesi, bilet satın alırken sigorta
                  seçimi yapmış iseniz sigorta işlemlerinin yapılması,
                  şikayetlerinizin/taleplerinizin Şirketimize aktarılabilmesi ve
                  takibi, talebinize bağlı olarak kombine üyelik ve sadakat
                  programı üyelik işlemleriniz ile akreditasyon işlemlerinin
                  yapılabilmesi ve bu üyelikler kapsamında size bilgi
                  verilebilmesi, yapılan işlem kayıtlarının
                  yedeklenmesi/arşivlenmesi, depolanması ve saklanması, ilgili
                  mevzuat hükümlerince bilgi saklama, raporlama, bilgilendirme
                  yükümlülüklerine uyulması ve yasal yükümlülüklerimizin ifası
                  amaçlarıyla işlenebilmektedir. Lüleburgaz Belediyesi, kişisel
                  verilerinizi burada belirtilen işleme amaçları dışında
                  kullanmayacak, işlemeyecek, açık rızanız-onayınız ya da ilgili
                  mevzuatta öngörülen sebepler olmaksızın 3. kişilere
                  aktarmayacak ve/veya ifşa etmeyecektir. KVKK madde 5/2 de
                  düzenlenen ve aşağıda da aktardığımız açık rıza gerektirmeyen
                  durumlar ayrı ve saklıdır.
                </p>
                <p className="text-justify">&nbsp;</p>
                <p className="text-justify">
                  Yukarıda belirtilen amaçlarla, kişisel verilerinizin
                  aktarılabileceği kişi/kuruluşlar: Kişisel Verileriniz; tercih
                  ettiğiniz ödeme şekline bağlı olarak ilgili Bankalara, ilgili
                  online ödeme sistemlerine, bilet satın alırken sigorta seçimi
                  yapmış iseniz ilgili sigorta şirketine ve aracı kurumlarına,
                  tercih ettiğiniz teslimat seçimi kapsamında ilgili
                  dağıtım/kargo-kurye şirketlerine, kombine üyelik ve sadakat
                  programı üyeliği gibi özel bir üyelik satın almış iseniz,
                  ilgili spor kulüplerine ve program üyesi olan ilgili
                  Vakıflara/firmalara, bilet satın alırken bağış seçimi yapmış
                  iseniz ilgili kuruma, şikayetlerinizin giderilmesi ve size
                  daha iyi bir hizmet sunabilmemiz için ilgili alt yapı
                  sağlayıcısına, e-faturalama/e-arşiv faturalama işlemleri
                  kapsamında ilgili kurum/kuruluş ve alt yapı sağlayıcılarına,
                  yapılan işlem kayıtlarının
                  yedeklenmesi/saklanması/arşivlenmesi/depolanması için yurt içi
                  ve dışında birlikte çalıştığımız ilgili sunucu barındırma,
                  arşivleme, depolama ve yazılım hizmetleri sağlayıcılarına,
                  gerekmesi durumunda Şirketimizin ana hissedarına, gereklilik
                  olması halinde doğrudan/dolaylı ilgili yurtiçi/yurtdışı
                  iştiraklerine; şikayetlerinizin ya da hasıl olan bir sorunun
                  giderimi ya da yasal yükümlülüklerimizin ifası için
                  Şirket’imizin ilgili çalışanlarına, hukuk, mali ve vergi
                  danışmanlarına, ilgili denetçilerine ve de mevzuat
                  hükümlerinin izin verdiği ilgili kişi, kurum ve kuruluşlara
                  izin verilen kapsamda ve sadece gerektiği kadarı ile
                  aktarılabilecektir. &nbsp;Kişisel verileriniz bu maddede
                  belirtilen ilgili üçüncü kişilere aktarılırken sadece
                  gerektiği kadarı ile ve ilgisi kapsamında bir aktarım
                  yapılacaktır. KVKK madde 5/2 de düzenlenen ve aşağıda da
                  aktardığımız açık rıza gerektirmeyen durumlar ayrı ve
                  saklıdır.
                </p>
                <p className="text-justify">
                  <br />
                  <br />
                  &nbsp;
                </p>
                <p className="text-justify">Kişisel verilere ait haklarım nelerdir?</p>
                <p className="text-justify">&nbsp;</p>
                <p className="text-justify">
                  Kanun’un 11. Maddesi Çerçevesinde Haklarınız ve İstisnaları ;
                </p>
                <p className="text-justify">&nbsp;</p>
                <p className="text-justify">
                  Şirketimize yazılı olarak veya Kurulun belirleyeceği diğer
                  yöntemlerle başvurarak;
                </p>
                <p className="text-justify">a) Kişisel verilerinizin işlenip işlenmediğini öğrenme,</p>
                <p className="text-justify">
                  b) Kişisel verileriniz işlenmişse buna ilişkin bilgi talep
                  etme,
                </p>
                <p className="text-justify">
                  c) Kişisel verilerinizin işlenme amacını ve bunların amacına
                  uygun kullanılıp kullanılmadığını öğrenme,
                </p>
                <p className="text-justify">
                  ç) Kişisel verilerinizin yurt içinde veya yurt dışında
                  aktarıldığı üçüncü kişileri bilme,
                </p>
                <p className="text-justify">
                  d) Kişisel verilerinizin eksik veya yanlış işlenmiş olması
                  halinde bunların düzeltilmesini isteme,
                </p>
                <p className="text-justify">
                  e) Kanun’un 7. maddesinde öngörülen şartlar çerçevesinde
                  kişisel verilerinizin silinmesini veya yok edilmesini isteme,
                </p>
                <p className="text-justify">
                  f) d ve e bentleri uyarınca yapılan işlemlerin, kişisel
                  verilerinizin aktarıldığı üçüncü kişilere bildirilmesini
                  isteme,
                </p>
                <p className="text-justify">
                  g) İşlenen verilerinizin münhasıran otomatik sistemler
                  vasıtasıyla analiz edilmesi suretiyle aleyhinize bir sonucun
                  ortaya çıkmasına itiraz etme ve
                </p>
                <p className="text-justify">
                  ğ) Kişisel verilerinizin kanuna aykırı olarak işlenmesi
                  sebebiyle zarara uğramanız hâlinde zararın giderilmesini talep
                  etme hakkına sahipsiniz.
                </p>
                <p className="text-justify">&nbsp;</p>
                <p className="text-justify">
                  Yukarıdaki maddelerle ilgili daha detaylı bilgi almak ve
                  kişisel verileriniz hakkındaki talepleriniz için lütfen formu
                  doldurun.
                </p>
                <p className="text-justify">&nbsp;</p>
                <p className="text-justify">
                  Kişisel verilerle ilgili taleplerinizi; kimliğinizi tespit
                  edici gerekli bilgileri/belgeleri de eklemek suretiyle,
                  kullanmak istediğiniz hakkınıza yönelik açıklamalarınızla
                  birlikte, Şirketimizin Yardım adresindeki ilgili yardım
                  sayfalarındaki formu doldurarak elektronik ortamda ve/veya
                  Ayazağa Mah. Azerbaycan Cad. Blok:1B, No:3B İç kapı:2, 34396
                  Sarıyer - İstanbul, Türkiye adresine imzalı bir
                  dilekçe/müracaat yazısı ile iletebilirsiniz. Ayrıca, talebin
                  iletimi konusunda Kurulun başkaca yöntemler belirlemesi
                  durumunda söz konusu yöntemlerle de talebinizi iletebilmeniz
                  mümkün olacaktır. Şirketimiz, başvuruda yer alan
                  taleplerinizi, talebin niteliğine göre en kısa sürede ve en
                  geç otuz gün içinde ücretsiz olarak sonuçlandıracaktır.
                </p>
                <p className="text-justify">&nbsp;</p>
                <p className="text-justify">
                  Ancak, işlemin ayrıca bir maliyet gerektirmesi hâlinde,
                  Kurulca belirlenen tarifedeki ücret alınabilir. Şirketimiz
                  talebi kabul etme veya gerekçesini açıklayarak reddetme ve
                  cevabını ilgili kişiye yazılı olarak veya elektronik ortamda
                  bildirme hakkına sahiptir. Başvuruda yer alan talebin kabul
                  edilmesi hâlinde Şirketimiz talebinizin gereğini mevzuatta
                  belirlenen süre içerisinde yerine getirecektir. Başvurunun
                  Şirketimizin hatasından kaynaklanması hâlinde alınan bir ücret
                  varsa, ücret ilgiliye iade edilecektir. Başvurunun
                  reddedilmesi, verilen cevabın yetersiz bulunması veya
                  süresinde başvuruya cevap verilmemesi hâllerinde; ilgili
                  kişinin, Şirketimizin cevabını öğrendiği tarihten itibaren
                  otuz ve her hâlde başvuru tarihinden itibaren altmış gün
                  içinde Kurula şikâyette bulunabilme hakkı vardır. KVKK madde
                  14/2 hükmüne istinaden, veri sorumlusu olan Şirketimize
                  yukarıda aktardığımız başvuru yolu tüketilmeden Kurula şikâyet
                  yoluna başvurulamaz. Kişilik hakları ihlal edilenlerin, genel
                  hükümlere göre tazminat hakkı saklıdır.
                </p>
                <p className="text-justify">&nbsp;</p>
                <p className="text-justify">
                  Bununla birlikte, KVKK madde 28/2 hükmüne istinaden; KVKK’nın
                  amacına ve temel ilkelerine uygun ve orantılı olmak kaydıyla
                  veri sorumlusunun aydınlatma yükümlülüğünü düzenleyen 10 uncu
                  maddesinin ve zararın giderilmesini talep etme hakkı hariç,
                  ilgili kişinin haklarını düzenleyen 11 inci maddesinin a)
                  Kişisel veri işlemenin suç işlenmesinin önlenmesi veya suç
                  soruşturması için gerekli olması b) İlgili kişinin kendisi
                  tarafından alenileştirilmiş kişisel verilerin işlenmesi c)
                  Kişisel veri işlemenin kanunun verdiği yetkiye dayanılarak
                  görevli ve yetkili kamu kurum ve kuruluşları ile kamu kurumu
                  niteliğindeki meslek kuruluşlarınca, denetleme veya düzenleme
                  görevlerinin yürütülmesi ile disiplin soruşturma veya
                  kovuşturması için gerekli olması ç) Kişisel veri işlemenin
                  bütçe, vergi ve mali konulara ilişkin olarak Devletin ekonomik
                  ve mali çıkarlarının korunması için gerekli olması
                  durumlarında uygulanmayacağını da belirtiriz.
                </p>
                <p className="text-justify">&nbsp;</p>
                <p className="text-justify">
                  Açık Rıza Gerektirmeyen Haller : KVKK’nın 5. Maddesinin 2.
                  fıkrası uyarınca, a) kanunlarda açıkça öngörülmesi, b) Fiili
                  imkânsızlık nedeniyle rızasını açıklayamayacak durumda bulunan
                  veya rızasına hukuki geçerlilik tanınmayan kişinin kendisinin
                  ya da bir başkasının hayatı veya beden bütünlüğünün korunması
                  için zorunlu olması c) bir sözleşmenin kurulması veya ifasıyla
                  doğrudan doğruya ilgili olması kaydıyla, sözleşmenin
                  taraflarına ait kişisel verilerin işlenmesinin gerekli olması,
                  ç) veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi
                  için zorunlu olması, d) ilgili kişinin kendisi tarafından
                  alenileştirilmiş olması, e) bir hakkın tesisi, kullanılması
                  veya korunması için veri işlemenin zorunlu olması, f) ilgili
                  kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla
                  veri sorumlusunun meşru menfaatleri için veri işlenmesinin
                  zorunlu olması hallerinden herhangi birinin varlığı halinde,
                  ilgili kişinin açık rızası aranmaksızın kişisel verilerin
                  işlenmesi mümkündür.
                </p>
                <p className="text-justify">&nbsp;</p>
                <p className="text-justify">Ticaret Ünvanı : Lüleburgaz Belediyesi</p>
                <p className="text-justify">&nbsp;</p>
                <p className="text-justify">
                  İletişim Adresi : Özerler Mah, Hal Sok No:1 Lüleburgaz
                  Belediyesi - Lüleburgaz, Kırklareli
                </p>
                <p className="text-justify">&nbsp;</p>
                <p className="text-justify">İletişim Telefonu : 0288 417 10 12</p>
                <p className="text-justify">İletişim Telefonu : 0288 417 10 73</p>
                <p className="text-justify">İletişim Faks Numarası : 0288 417 47 79</p>
              </>
            </span>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default KVKKPage;

import React, { useEffect } from "react";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { TaxonomyType } from "data/types";
import HomeSlider from "components/HomeSlider/HomeSlider";
import SectionGridFeatureProperty from "./SectionGridFeatureProperty";

function PageHome() {
  // CUSTOM THEME STYLE
  useEffect(() => {
    const $body = document.querySelector("body");
    if (!$body) return;
    $body.classList.add("theme-purple-blueGrey");
    return () => {
      $body.classList.remove("theme-purple-blueGrey");
    };
  }, []);
  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism/>

      {/* SECTION HERO */}
      <div className="container px-1 sm:px-4 mb-0 py-12">
        <HomeSlider className="" uniqueClassName="PageHome_"/>
        {/* <SectionHero3 className="" /> */}
      </div>

      <div className="container relative space-y-24 mb-24 ">
        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionGridFeatureProperty />
          {/* <SectionGridAuthorBox boxCard="box2" /> */}
        </div>
        
        {/* SECTION 1 */}

       {/*  <Heading
        desc=""
        isCenter={true}
      >
        Salonlarımız
      </Heading> */}
        {/* <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-6 lg:col-span-4 flex">
            <CardCategory6 taxonomy={DEMO_CATS_2[0]} />
          </div>
          <div className="col-span-12 sm:col-span-6 lg:col-span-4 flex">
            <CardCategory6 taxonomy={DEMO_CATS_2[1]} />
          </div>
          <div className="col-span-12 sm:col-span-6 lg:col-span-4 flex">
            <CardCategory6 taxonomy={DEMO_CATS_2[2]} />
          </div>
        </div> */}

        {/* SECTION */}
        
        {/* <SectionGridCategoryBox /> */}

        

        {/* SECTION */}
        {/* <SectionHowItWork /> */}

        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionGridFeaturePlaces />
        </div> */}

        {/* SECTION */}
        {/* <SectionSubscribe2 /> */}
      </div>
    </div>
  );
}

export default PageHome;

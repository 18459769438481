import React from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CommonLayout from "./CommonLayout";

const GizlilikPage = () => {
  return (
    <div>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">
          Gizlilik Beyanı ve Kişisel Veriler Politikası
          </h2>
          <h6 className="text-xs font-semibold text-slate-500">
            (Son güncelleme: 8 Şubat 2023)
          </h6>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="w-full">
            <span className="text-neutral-700 dark:text-neutral-300 block">
              <div
                style={{
                  margin: 0,
                  padding: "0px 0px 10px 0px",
                  display: "block",
                  color: "#566470",
                  fontSize: 13,
                }}
              >
                <p>
                  Lüleburgaz Belediyesi’ne hoş geldiniz. Bizim için kişisel
                  bilgilerinizi korumanın çok önemli olduğunu ve bunların
                  gizliliğine saygı duyduğumuzu bilmenizi istiyoruz. Bu Gizlilik
                  Politikasını, bizimle paylaştığınız kişisel bilgilerinizi
                  nasıl kullandığımızı ve koruduğumuzu açıklamak için
                  hazırladık. Bu Politika www.luleburgaz.bel.tr (“Site”) ve
                  hizmetlerimizi kullanan, ayrıca bilet satın alan tüm
                  kullanıcılar ile bize verilen tüm bilgiler için geçerlidir.
                  Bilet satın almak suretiyle, Gizlilik Beyanı hükümlerini
                  okuduğunuzu, incelediğinizi ve içeriğini aynen kabul etmiş
                  sayılacağınızı önemle belirtiriz. Web sitemizi ve
                  hizmetlerimizi kullanmak ve/veya bilet satın almak suretiyle
                  işbu Gizlilik Politikasında belirtilen uygulamaları ve
                  kuralları kabul etmektesiniz.
                </p>
                <p>
                  <br /> Lüleburgaz Belediyesi, gizlilik konusunda Türkiye’deki yasal
                  düzenlemelere uymaktadır. Lüleburgaz Belediyesi, bu web sitesinin diğer
                  ülkelerin yasalarına tabiiolduğu ya da bunlara uygun olarak
                  işletildiği ya da bu web sitesinin herhangi bir kısmının
                  Türkiye dışında herhangi bir yetkili yargı bölgesinde
                  kullanımı için uygun ya da hazır olduğu hususunda bir
                  taahhütte bulunmamaktadır. Web sitemizi kullanmayı seçenler,
                  bunu kendi inisiyatifleri doğrultusunda riskleri kendilerine
                  ait olarak yapmaktadır ve tüm yerel kanunlar, kural ve
                  düzenlemelere uymakla yükümlüdürler. Bu web sitesini kullanan
                  Türkiye dışından ziyaretçiler, işbu sitenin Türkiye
                  Cumhuriyeti kanun ve düzenlemelerine tabii olduğunu kabul
                  etmekte ve kendi ulusal yasaları kapsamında ortaya çıkacak tüm
                  taleplerinden feragat etmektedirler.
                  <br /> Lüleburgaz Belediyesi, işbu Gizlilik Politikasının hükümlerini
                  mevzuat kapsamında değiştirme, üzerinde değişiklik, ekleme ya
                  da çıkarma yapma hakkını saklı tutar. İşbu Gizlilik
                  Politikasında yapılan değişiklikler yine bu web sitesinde
                  yayınlanacaktır. Gizlilik politikamızın en güncel halinden
                  haberdar olmaya devam etmek için zaman zaman web sitemizi
                  kontrol etmenizi öneririz. Bu sayfanın üzerindeki “Son
                  Güncelleme” tarihine bakarak son ziyaretinizden beri sitemizin
                  Gizlilik Politikasının revize edilip edilmediğini
                  anlayabilirsiniz. Lütfen bu Gizlilik Politikasını dikkatle
                  okuyun. Sitemizi kullanmanız ve/veya bilet satın almanız,
                  herhangi bir sınırlandırma, uygunluk ya da değişiklik
                  olmaksızın işbu Gizlilik Politikasına bağlı olmayı kabul
                  etmeniz anlamına gelmektedir. Herhangi bir zamanda, buradaki
                  tüm hüküm ve koşulları kabul etmediğiniz takdirde, bu sitenin
                  kullanımına derhal son vermeniz gerekmektedir.
                </p>
                <p>
                  <br />
                  <strong>1. Hangi Bilgileri Topluyoruz</strong>
                </p>
                <p>
                  <br /> Web sitemiz aracılığıyla iki çeşit bilgi topluyoruz:
                  “Kişisel Bilgiler” (ya da “PII”) ve “Kişisel Olmayan Bilgiler”
                  (ya da “PII-Olmayan Bilgiler”). Kişisel Bilgiler, sizi bir
                  birey olarak tanımlayan ya da tanımlayabilen bilgilerdir
                  (ikamet adresi, telefon numarası ya da fatura bilgileri gibi)
                  ve Kişisel Olmayan Bilgiler ise toptan bilgiler, demografik
                  bilgiler, IP adresleri ve doğrudan kimliğinizi ortaya
                  çıkarmayan diğer bilgilerdir.
                </p>
                <p>
                  <strong>
                    <br /> a. Kişisel Olmayan Bilgiler:{" "}
                  </strong>{" "}
                  Sitemizi ziyaret ettiğinizde veya sitemizle etkileşime
                  geçtiğinizde Lüleburgaz Belediyesi ve Lüleburgaz Belediyesi’e hizmet sağlamak için
                  Lüleburgaz Belediyesi’in sözleşme yaptığı üçüncü kişiler Kişisel Olmayan
                  Bilgilerinizi (örneğin, ziyaret ettiğiniz Site sayfalarının
                  bir katalogunu) toplayabilir. Kişisel Olmayan Bilgiler, anonim
                  ve sizi kişisel olarak tanımlamayan bilgilerdir. Kişisel
                  Olmayan Bilgiler genellikle Sitemiz vasıtasıyla dört farklı
                  kaynaktan toplanır: Internet protokol adresleri ve web
                  logları; çerezler (cookies), piksel etiketleri ve diğer
                  benzeri teknolojiler; ve aşağıda açıklanan şekilde sizin
                  isteyerek verdiğiniz diğer Kişisel Olmayan Bilgiler.
                </p>
                <p>
                  <strong>
                    <br /> i. IP Protokol Adresleri ve Web Logoları:{" "}
                  </strong>{" "}
                  IP adresinizi sunucularımızdaki problemleri teşhis etmek, web
                  sitemizi yönetmek, site/uygulama ile bağlantınızı en hızlı
                  şekilde sağlayacak veri servisine ulaşmanızı temin etmek ve
                  tercihiniz doğrultusunda ziyaretlerinizde kullanılan dil ve
                  Site'ye erişilen bölge açısından kişiselleştirilmiş bir
                  deneyim sunmak için alıyor ve saklıyoruz. Sitemizin web
                  sunucuları otomatik olarak, ziyaretçilerin Internet Protokol
                  (IP) adreslerini ve log dosyalarını toplar. IP adresiniz,
                  Internet Servis Sağlayıcınız (ISP) tarafından bilgisayarınıza
                  otomatik olarak tayin edilen bir kimlik numarasıdır. Numara,
                  siz Siteyi ziyaret ettiğinizde ziyaret(ler)inizin zamanı ve
                  ziyaret ettiğiniz sayfa(lar) ile birlikte sunucu log
                  dosyalarımızda otomatik olarak tanımlanır ve saklanır. Bu
                  bilgiler, genel olarak toplanır ve herhangi bir Kişisel Bilgi
                  içermez.
                </p>
                <p>
                  {" "}
                  IP adresinizi ve tüm kullanıcıların IP adreslerini sadece Site
                  kullanım düzeylerini hesaplama, Site sunucularının
                  problemlerini teşhis etme ve Siteyi idare etme gibi amaçlarla
                  kullanırız. IP adreslerini toplamak internette standart bir
                  uygulamadır ve birçok web sitesi tarafından otomatik olarak
                  yapılır. Yukarıda tanımlanan IP adreslerinin Kişisel Olmayan
                  kullanımına ilave olarak, dolandırıcılık ve benzeri suçları
                  önlemek amacıyla Kişisel Bilgilerinizle IP adresinizi
                  birbiriyle ilişkilendirebiliriz. <br />
                  <strong>
                    <br /> ii. Çerezler (Cookies):{" "}
                  </strong>{" "}
                  Bizler ve üçüncü kişi hizmet sağlayıcıları, sitemizi nasıl
                  kullandığınız hakkında bilgi toplamak için “çerez” denilen
                  standart bir teknolojiyi kullanabilir. Çerezler
                  bilgisayarınızda kalır ve sitemizin bilinen ziyaretçisi olarak
                  bilgisayarınızın tarayıcısını tanımasına yardım eder. Örneğin
                  Lüleburgaz Belediyesi hesabınıza girdiğinizde e-posta adresinizi ve isminizi
                  kaydetmek için çerezler kullanılacaktır. Böylece aynı
                  bilgisayardan bir dahaki ziyaretinizde, işleminizi
                  hızlandırmak ve daha basit kılmak için isim ve e-posta
                  adresinizi “hatırlayacağız”. Zaman zaman, sitemizi idare
                  etmemize yardım eden “oturum çerezi” de kurulabilir. Oturum
                  çerezi siz browserınızı kapattığınızda sona erer ve sona
                  erdikten sonra da sizin hakkınızda herhangi bir bilgi tutmaz.
                  Çerezlerin kullanımıyla bilgilerin toplanmasını istemiyorsanız
                  çerezlere ilişkin seçimleri size sunması için browser
                  tercihlerinizi değiştirmelisiniz. Çerezleri reddetmeyi
                  seçerseniz, sitemizin belli hizmetlerini (örn. siteye giriş
                  yapmanızı gerektiren hizmetler) kullanamayabilirsiniz.
                  Çerezler ile toplanan kişisel veriler söz konusu olduğu
                  takdirde, Kişisel Verilerin Korunması Hakkında Kanun (KVKK)
                  uyarınca, aşağıda detaylarını aktardığımız yöntem ve içerikle,
                  sizlerden izin-onay talep edilecek ve sadece izin-onay
                  verdiğiniz verileriniz, izin verdiğiniz kapsamda
                  işlenebilecektir. .Mevzuat hükümleri ayrı ve saklıdır.
                </p>
                <p>
                  <strong>
                    <br /> iii. Piksel Etiketleri (Pixel Tags):{" "}
                  </strong>{" "}
                  Biz ve servis sağlayıcılarımız, sonraki ziyaretinizde size
                  daha faydalı bilgiler verebilmek ve daha kişiselleştirilmiş
                  bir deneyim sunabilmek için web sitemizi kullanımınızı izleyen
                  sitemizin sayfalarıyla bağlantılı olarak “piksel etiketler”,
                  “web yol göstericileri (beacon)” “clear GIFs” (birlikte
                  “Piksel Etiketleri” olarak anılacaktır) ya da benzeri araçları
                  ve HTML formatlı e-posta mesajları da kullanabiliriz. Bir
                  Piksel Etiket, normalde sitemizin ziyaretçilerinin görmediği
                  ve ziyaretçilerin hard diskleri üzerindeki çerezlerle
                  ilişkilendirilebilecek genellikle tek piksellik (1X1) bir
                  elektronik görüntüdür. Piksel Etiketler Kişisel Bilgiler
                  içermez ve markalı hizmetler sunmak ve promosyon ya da reklam
                  kampanyalarının etkinliğini tespit etmeye yardım etmek için
                  sitemizin belli sayfalarını ziyaret eden kullanıcıları
                  saymamıza imkan sağlar. HTML formatlı e-posta mesajlarında
                  kullanıldığında Piksel Etiketler gönderene bir e-postanın
                  açılıp açılmadığını söyleyebilir.
                  <br />
                  <strong />
                </p>
                <p>
                  <strong>b. Kişisel Bilgiler</strong> : Lüleburgaz Belediyesi , internet
                  sitesini ziyaret eden, uygulamalarını kullanan
                  Kullanıcıların/ziyaretçilerin/müşterilerin özel hayatlarına
                  saygı gösterilmesine ve kişisel verilerinin güvenliğine dikkat
                  etmektedir. (www.luleburgaz.bel.tr sitesi ve uygulamalarının tamamı
                  kısaca "Site" olarak anılacaktır)
                </p>
                <p>
                  {" "}
                  Lüleburgaz Belediyesi, sizlerle ilgili elde edilen kişisel verilerin
                  saklanmasından ve güvenliğinden sorumludur. Ayrıca, kişisel
                  verilerinizin sizlerin onayı olmaksızın üçüncü kişilere
                  iletilemeyeceğini ve sizlere bildirilen amaçlar dışında başka
                  amaçlarla kullanılamayacağını da belirtiriz.{" "}
                </p>
                <p>
                  <strong />
                </p>
                <p>
                  <strong>
                    Kullanıcıların Kişisel Verilerine İlişkin Hakları
                  </strong>
                </p>
                <p>
                  {" "}
                  Kişisel Verilerin Korunması Kanunu'nun (“KVKK”) 11. maddesi
                  gereği Kullanıcılar ve Üyeler; Lüleburgaz Belediyesi’e ve/veya Lüleburgaz Belediyesi
                  tarafından açıklanacak temsilciye başvurarak,
                </p>
                <p>
                  {" "}
                  . kendisi ile ilgili kişisel veri işlenip işlenmediğini
                  öğrenme,{" "}
                </p>
                <p>
                  {" "}
                  · kişisel verileri işlenmişse buna ilişkin bilgi talep etme,{" "}
                </p>
                <p>
                  {" "}
                  · kişisel verilerin işlenme amacını ve bunların amacına uygun
                  kullanılıp kullanılmadığını öğrenme,{" "}
                </p>
                <p>
                  {" "}
                  · yurt içinde veya yurt dışında kişisel verilerin aktarıldığı
                  üçüncü kişileri bilme,{" "}
                </p>
                <p>
                  {" "}
                  · kişisel verilerin eksik veya yanlış işlenmiş olması halinde
                  bunların düzeltilmesini isteme,{" "}
                </p>
                <p>
                  {" "}
                  · KVKK'nın 7. maddesinde öngörülen şartlar çerçevesinde
                  kişisel verilerin&nbsp;silinmesini / yok edilmesini isteme,{" "}
                </p>
                <p>
                  {" "}
                  · Yukarıda belirtilen (d) ve (e) bentleri uyarınca yapılan
                  işlemlerin kişisel verilerin aktarıldığı üçüncü kişilere
                  bildirilmesini isteme,{" "}
                </p>
                <p>
                  {" "}
                  · işlenen verilerin münhasıran otomatik sistemler vasıtasıyla
                  analiz edilmesi sureti ile kişinin kendisi aleyhine bir
                  sonucun ortaya çıkmasına itiraz etme,{" "}
                </p>
                <p>
                  {" "}
                  · kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle
                  zarara uğraması hâlinde zararın giderilmesini talep etme,{" "}
                </p>
                <p>
                  {" "}
                  haklarına sahiptir. Lüleburgaz Belediyesi tarafından atanacak Veri Sorumlusu
                  temsilcisi, yasal altyapı sağlandığında Veri Sorumluları
                  Sicilinde ilan edilerek mevzuata uygun
                  şekilde,&nbsp;www.luleburgaz.bel.tr&nbsp;uzantılı web adresinde
                  duyurulacaktır. Kişisel verilerinizle ilgili KVKK md. 11
                  uyarınca sahip olduğunuz haklarınızı kullanmak için ya da
                  kişisel verileriniz ile ilgili sorularınız kapsamında bizimle
                  her zaman, info@Lüleburgaz Belediyesi.com adresine elektronik posta mesajı
                  (mail) göndererek ya da +90 (850) 755 5555 telefon numarasını
                  aramak sureti ile irtibata geçebilirsiniz.{" "}
                </p>
                <p>
                  <strong />
                </p>
                <p>
                  <strong>2. Topladığımız bilgileri nasıl kullanıyoruz?</strong>
                </p>
                <p>
                  <br /> Bizimle paylaştığınız kişisel bilgileri korumanın çok
                  önemli olduğunu biliyoruz. Bu kişisel bilgileri izinsiz erişim
                  ve açıklamadan korumaya yardımcı olmak için mevzuat kapsamında
                  uygun güvenlik tedbirlerini alıyoruz. Kişisel bilgilerinize
                  ancak mevzuat hükümleri dahilinde ulaşabilir, temin edebilir,
                  kullanabilir, işleyebilir ve/veya yurt içine/dışına
                  aktarabiliriz. Ayrıca, bilgisayarlarınızdan bizim
                  bilgisayarlarımıza kart bilgilerinizi göndermeden önce kredi
                  kartı numaraları ve diğer hassas bilgileri de şifreliyoruz.
                  Kişisel bilgilerinize yetkisiz erişimi önlemeye yardımcı olmak
                  için firewallları da kullanıyoruz.
                </p>
                <p>
                  <br /> Web sitemizi kullanırken kendinizi güvende
                  hissedebilirsiniz. Ancak, hiçbir sistem tamamıyla güvenli
                  değildir. Bilgilerinizi korumak için gerekli tüm tedbirleri
                  alsak da , güvence dışında kalma ihtimaliniz de vardır. Bu
                  sebeple kişisel bilgilerinizi taşırken ve açıklarken çok
                  dikkat etmeniz gerekir. Örneğin, e-posta ile kişisel bilgi
                  göndermekten kaçının.
                </p>
                <p>
                  {" "}
                  Kural olarak, kişisel verilerin ilgili kişinin açık rızası
                  olmaksızın işlenmesi mümkün olmamakla birlikte,
                  <u>
                    <strong>
                      “&nbsp;bir sözleşmenin kurulması veya ifasıyla doğrudan
                      doğruya ilgili olması kaydıyla, sözleşmenin taraflarına
                      ait kişisel verilerin işlenmesinin gerekli olması”,
                    </strong>
                  </u>
                  ir hakkın tesisi, kullanılması veya korunması için veri
                  işlemenin zorunlu olması”, “ Veri sorumlusunun hukuki
                  yükümlülüğünü yerine getirebilmesi için zorunlu olması”, “
                  ilgili kişinin kendisi tarafından alenileştirilmiş olması”,
                  “İlgili kişinin temel hak ve özgürlüklerine zarar vermemek
                  kaydıyla, veri sorumlusunun meşru menfaatleri için veri
                  işlenmesinin zorunlu olması” “Fiili imkânsızlık nedeniyle
                  rızasını açıklayamayacak durumda bulunan veya rızasına hukuki
                  geçerlilik tanınmayan kişinin kendisinin ya da bir başkasının
                  hayatı veya beden bütünlüğünün korunması için zorunlu olması”
                  ve/veya “Kanunlarda açıkça öngörülmesi”&nbsp;
                  <strong>
                    durumlarından herhangi birinin mevcudiyeti
                    halinde,&nbsp;ilgili kişinin açık rızası aranmaksızın
                    kişisel verilerinin işlenmesi mümkündür.{" "}
                  </strong>
                </p>
                <p>
                  <strong>
                    <br /> a. Kişisel Olmayan Bilgiler{" "}
                  </strong>
                </p>
                <p>
                  <strong>
                    <br />
                  </strong>
                  <strong>
                    {" "}
                    Kişisel Olmayan Bilgiler şahsen sizi tanımlayamayacağı için
                    bu bilgileri her türlü meşru amaçla kullanabiliriz. Örneğin,
                    ziyaret saatlerini, sitemizde harcanan ortalama süreyi ve
                    incelenen sayfaları ölçmek ve sunucularımızdaki problemleri
                    teşhis etmek ve sitemizi yönetmek, sitemizin özelliklerini
                    ve fonksiyonelliğini analiz etmek ve iyileştirmek için
                    kullanabiliriz.&nbsp;
                  </strong>
                  Ayrıca, şahsen sizi tanımlamayan kişisel olmayan bu bilgileri
                  herhangi bir amaçla üçüncü kişilerle paylaşma hakkımız
                  saklıdır. Bu bilgileri, reklam amaçlarıyla kullanılmak üzere
                  veya topluca ya da herhangi bir Kişisel Bilginizi
                  açıklamayacak başka şekillerde Lüleburgaz Belediyesi/Live Nation
                  Entertainment ailesinin parçası olmayan üçüncü kişilerle de
                  paylaşabiliriz.
                </p>
                <p>
                  <strong>
                    <br /> b. Kişisel ve/veya Özel Nitelikli Kişisel Verilere
                    Dair Açık Onay:&nbsp;
                  </strong>{" "}
                  Internet sitemizden etkinlik bileti satın alan Lüleburgaz Belediyesi
                  müşterileri, internet sitemizin kullanıcıları ve/veya diğer
                  kişisel veri paylaşan kişiler, sitemizde yayımlanan "Kişisel
                  Verilerin Korunması Kanunu Kapsamındaki Onay Metni"nin üzerine
                  “tik” atmak sureti ile kişisel /veya özel nitelikli kişisel
                  verilerinin alınmasına, işlenmesine ve yurt içine/dışına
                  aktarılmasına yasal düzenlenmenin öngördüğü kapsamda izin
                  vermiş olurlar.
                </p>
                <p>
                  {" "}
                  Ayrıca, Çağrı Merkezlerimizden hizmet alan müşterilerimizin;
                  “kişisel verilerin alınmasına, işlenmesine ve aktarımına”
                  yönelik çağrı merkezi tuşlama tercihlerine bağlı olarak
                  kişisel veriler temin edilmekte, işlenmekte ve yurt içine/yurt
                  dışına aktarılmaktadır{" "}
                </p>
                <p>
                  Kişinin açık onayının gerektiği durumlarda, kişisel veriler
                  bakımından gerekli onaylar/izinler verilmediği takdirde,
                  Site'de bazı özelliklerin kullanım dışı kalabileceği, hizmetin
                  kısmen veya tamamen sunulamayacağı, başka bir anlatımla bilet
                  satın alma işleminin yapılamayabileceği önemle dikkate
                  alınmalıdır. Zira, mevzuat hükümleri kapsamında açık onay
                  alınmasının gerekmediği durumlar ve istisnalar saklı kalmak
                  kaydı ile, etkinlik bileti satın alabilmek için bazı kişisel
                  verilerin temin edilmesinin, işlenmesinin ve aktarımının
                  gerekli olduğu göz ardı edilmemelidir.&nbsp;Mevzuat hükümleri
                  kapsamında açık onay alınmasının gerekmediği durumlar saklı
                  kalmak kaydıyla, Kişisel verilerin temin edilmesine,
                  işlenmesine ve/veya aktarıma dair gerekli onayın ya da ilgili
                  kişisel verilerin verilmemesi nedeni ile bilet satışı
                  yapılamamasından dolayı Lüleburgaz Belediyesi hiçbir sorumluluk kabul
                  etmemekte ve Lüleburgaz Belediyesi’in hiçbir sorumluluğu bulunmamaktadır.{" "}
                  <strong>
                    Kişisel verilerin temin edilmesine, işlenmesine ve/veya
                    aktarıma dair gerekli onayın ya da ilgili kişisel verilerin
                    verilmemesi nedeni ile bilet satışı yapılamamasından dolayı
                    Lüleburgaz Belediyesi hiçbir sorumluluk kabul etmemekte ve Lüleburgaz Belediyesi’in
                    hiçbir sorumluluğu bulunmamaktadır.
                  </strong>
                </p>
                <p>
                  {" "}
                  .{" "}
                  <strong>
                    <br />
                  </strong>
                </p>
                <p>
                  <strong>
                    Kişisel Verileriniz ne şekilde işlenebilecektir?
                  </strong>{" "}
                  Lüleburgaz Belediyesi olarak, veri sorumlusu sıfatı ile Şirketimiz ve
                  Şirketimiz adına perakende satış kanallarımız, çağrı
                  merkezlerimiz, kiosklarımız, bağlı şirketlerimiz tarafından ya
                  da internet sitelerimiz ile sosyal medya sayfalarımız ve/veya
                  bunlarla sınırlı olmamak üzere her türlü kanallar aracılığı
                  ile; onayınız dahilinde elde edilen kişisel ve/veya özel
                  nitelikli kişisel verileriniz;{" "}
                  <strong>onayınız kapsamında</strong> tamamen veya kısmen elde
                  edilebilir, kaydedilebilir, saklanabilir, depolanabilir,
                  değiştirilebilir, güncellenebilir , periyodik olarak kontrol
                  edilebilir, yeniden düzenlenebilir, sınıflandırılabilir,
                  işlendikleri amaç için gerekli olan ya da ilgili kanunda
                  öngörülen süre kadar muhafaza edilebilir, kanuni ya da hizmete
                  bağlı fiili gereklilikler halinde Şirketimizin birlikte
                  çalıştığı özel-tüzel kişilerle ya da kanunen yükümlü olduğu
                  kamu kurum ve kuruluşlarıyla ve/veya Türkiye'de veya yurt
                  dışında mukim olan ilgili 3. kişi gerçek kişi/tüzel kişilerle,
                  sigorta şirketleri, bankalar ile
                  paylaşılabilir/devredilebilir, kanuni ya da hizmete bağlı
                  fiili gereklilikler halinde yurtdışına aktarılabilir ya da
                  kullanılmasının engellenmesi de dahil olmak üzere işlenebilir.
                  Bu meyanda, Kanun uyarınca, onayınız dahilinde Şirketimizle
                  paylaştığınız kişisel ve/veya özel nitelikli kişisel
                  verilerinizin tamamen veya kısmen otomatik olan ya da herhangi
                  bir veri kayıt sisteminin parçası olmak kaydıyla otomatik
                  olmayan yollarla elde edilmesi, kaydedilmesi, depolanması,
                  muhafaza edilmesi, değiştirilmesi, yeniden düzenlenmesi,
                  açıklanması, aktarılması, devralınması, elde edilebilir hâle
                  getirilmesi, sınıflandırılması ya da kullanılmasının
                  engellenmesi gibi veriler üzerinde gerçekleştirilen her türlü
                  işlem tarafımızdan yapılabilecektir.
                </p>
                <p>
                  <strong>Kişisel verileriniz nasıl toplanmaktadır?</strong>{" "}
                  İnternet sitemiz, satış ve pazarlama departmanı
                  çalışanlarımız, müşteri ziyaretlerinde toplanan formlar,
                  dijital pazarlama ve çağrı merkezi/internet sitemiz/sosyal
                  medya/organizatörlerimiz/iş ortaklarımız ve bunlarla sınırlı
                  olmamak üzere her türlü kanallar aracılığıyla, kişisel ve/veya
                  özel nitelikli kişisel verileriniz, onayınız dahilinde sözlü,
                  yazılı veya elektronik ortamda toplanabilmektedir. Ayrıca,
                  Genel Müdürlük, perakende satış noktaları, kiosklar, internet
                  şubesi , sosyal medya ve çağrı merkezi gibi kanallar
                  aracılığıyla kişisel verileriniz onayınız dahilinde sözlü,
                  yazılı veya elektronik ortamda toplanabilir.
                </p>
                <p>
                  <strong />
                </p>
                <p>
                  <strong />
                </p>
                <p>
                  <strong />
                </p>
                <p>
                  <strong>
                    Kanunun Yayımından Önceki Verilerin Durumu Nedir?
                  </strong>{" "}
                  Kanun hükümleri uyarınca, “Bu Kanunun yayımı tarihinden önce
                  işlenmiş olan kişisel verileriniz, yayımı tarihinden
                  (07.04.2016) itibaren iki yıl içinde bu Kanun hükümlerine
                  uygun hâle getirilecektir. Buna karşın, Kanunun yayımı
                  tarihinden önce hukuka uygun olarak alınmış rızalar, bir yıl
                  içinde aksine bir irade beyanında bulunulmaması hâlinde, bu
                  Kanuna uygun kabul edilmiş sayılmaktadır”. Belirtiriz ki,
                  Lüleburgaz Belediyesi müşterileri, Lüleburgaz Belediyesi’e üye olmakla ve bilet satın
                  almakla Kullanım Koşullarını ve Gizlilik Politikası
                  hükümlerini kabul etmiş sayıldığından ve Gizlilik
                  Politikalarımızda, kişisel ve/veya özel nitelikli kişisel
                  verilerinizin teminine, işlenmesine ve aktarımına dair detaylı
                  düzenlemeler olduğundan, Kanun’un yayımı tarihinden önce temin
                  ettiğimiz ve işlenmiş olan kişisel /veya özel nitelikli
                  kişisel verilerinize ilişkin olarak, tarafımıza verilen
                  “hukuka uygun rızanız” bulunmaktadır. Dolayısı ile, Kanuna
                  istinaden bu Kanunun yayımı tarihinden önce hukuka uygun
                  olarak alınmış bu rızalarınız, bir yıl içinde aksine bir irade
                  beyanında bulunulmaması hâlinde, bu Kanuna uygun kabul edilmiş
                  sayılmaktadır.
                </p>
                <p>
                  <strong />
                </p>
                <p>
                  <strong />
                </p>
                <p>
                  <strong>
                    Kişisel verilerinizin işlenme amaçları ve hukuki sebepleri
                    nelerdir?
                  </strong>{" "}
                  En başta müşterilerimizin Lüleburgaz Belediyesi’den talep ettiği etkinlik
                  biletlerini satıp, pazarlamak ve dağıtabilmek, müşterilerimize
                  verdiğimiz hizmetleri sözleşmenin ve teknolojinin gereklerine
                  uygun şekilde yapabilmek, sizlere sunduğumuz ürün ve
                  hizmetlerimizi geliştirebilmek için bizimle paylaştığınız
                  kişisel ve/veya özel nitelikli kişisel verilerinizi 6698
                  sayılı Kişisel Verilerin Korunması Kanunu ve ilgili mevzuata
                  uygun olarak alıp, işleyeceğiz.
                </p>
                <p>
                  {" "}
                  Ayrıca, en başta etkinlik bileti satışı yapabilmek, mevzuat
                  kapsamında acente sıfatıyla sunabileceklerimiz de dahil olmak
                  üzere her türlü ürün ve hizmetlerde kullanılmak; işlem
                  sahibinin bilgilerini tespit ve de müşterilerimizin
                  Lüleburgaz Belediyesi’den temin ettiği hizmeti sunabilmemiz için kimlik,
                  adres ve diğer gerekli bilgileri kaydetmek; elektronik veya
                  kağıt ortamında işleme dayanak olacak tüm kayıt ve belgeleri
                  düzenlemek; mevzuat kapsamında ve yetkili otoritelerce talep
                  edilen bilgi saklama, raporlama, bilgilendirme vb
                  yükümlülüklere riayet etmek; talep edilen ürün / hizmetleri
                  sunabilmek ve talep ettiğiniz hizmetin ve de sözleşmenin
                  gereğini yerine getirmek, içeriğindeki sebepler de kişisel
                  verilerinizin işlenme amaç ve sebeplerini oluşturmaktadır.{" "}
                </p>
                <p>
                  {" "}
                  Kural olarak, kişisel verilerin ilgili kişinin açık rızası
                  olmaksızın işlenmesi mümkün olmamakla birlikte, “{" "}
                  <u>
                    {" "}
                    bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya
                    ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel
                    verilerin işlenmesinin gerekli olması”, “{" "}
                  </u>{" "}
                  Bir hakkın tesisi, kullanılması veya korunması için veri
                  işlemenin zorunlu olması”, “ Veri sorumlusunun hukuki
                  yükümlülüğünü yerine getirebilmesi için zorunlu olması”, “
                  ilgili kişinin kendisi tarafından alenileştirilmiş olması”,
                  “İlgili kişinin temel hak ve özgürlüklerine zarar vermemek
                  kaydıyla, veri sorumlusunun meşru menfaatleri için veri
                  işlenmesinin zorunlu olması”, “Fiili imkânsızlık nedeniyle
                  rızasını açıklayamayacak durumda bulunan veya rızasına hukuki
                  geçerlilik tanınmayan kişinin kendisinin ya da bir başkasının
                  hayatı veya beden bütünlüğünün korunması için zorunlu olması”
                  ve/veya “Kanunlarda açıkça öngörülmesi”
                  <strong>
                    durumlarından herhangi birinin mevcudiyeti
                    halinde,&nbsp;ilgili kişinin açık rızası aranmaksızın
                    kişisel verilerinin işlenmesi mümkündür..{" "}
                  </strong>
                </p>
                <p>
                  <strong />
                </p>
                <p>
                  {" "}
                  Kişisel verilerinizin kullanılma ve işlenme amaçlarının
                  detayları da aşağıda özetlenmektedir;{" "}
                </p>
                <p>
                  <strong>
                    <br /> i. Site:{" "}
                  </strong>{" "}
                  Bize verdiğiniz Kişisel Bilgileri, onayınız dahilinde, ilgi
                  alanlarınıza göre daha iyi bir site yapmak,
                  ilgilenebileceğiniz içerikleri göstermek ve içeriği
                  tercihlerinize göre ekrana getirmek için kullanabiliriz.
                  <br />
                  <strong />
                </p>
                <p>
                  <strong>
                    <br /> ii. İletişim:{" "}
                  </strong>{" "}
                  Kişisel Bilgilerinizi, ilgili mevzuatlar kapsamında alınan
                  onayınız dahilinde, size Lüleburgaz Belediyesi ve hizmetleri ile bazı
                  ortaklarımız adına promosyonlar hakkında bilgi vermek için
                  kullanabiliriz.
                  <strong>
                    <br /> iii. İdari Hususlar:{" "}
                  </strong>{" "}
                  Lüleburgaz Belediyesi, Site Kullanım Şartları ve/veya işbu Gizlilik
                  Politikasında herhangi bir değişiklik olması halinde sizinle
                  irtibata geçme ya da sizi sitemize ilişkin diğer teknik-idari
                  konular (örn. bir işlemi kolaylaştırma, tamamlamak ya da teyit
                  etmek için bağlantıya geçme) hakkında bilgilendirme hakkını
                  saklı tutar.
                </p>
                <p>
                  <strong>
                    <br /> iv. Taleplerin Yerine Getirilmesi ve İşlemlerin
                    Kolaylaştırılması:&nbsp;{" "}
                  </strong>{" "}
                  Lüleburgaz Belediyesi taleplerinizi yerine getirmek ve işlemlerini
                  kolaylaştırmak için (örn. biletlerinizi teslim etmek için)
                  onayınız dahilinde Kişisel Bilgilerinizi kullanabilir. Buna
                  karşın,
                  <strong>mevzuat uyarınca </strong> “{" "}
                  <u>
                    {" "}
                    “&nbsp;bir sözleşmenin kurulması veya ifasıyla doğrudan
                    doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait
                    kişisel verilerin işlenmesinin gerekli olması”, “{" "}
                  </u>{" "}
                  ir hakkın tesisi, kullanılması veya korunması için veri
                  işlemenin zorunlu olması”, “ Veri sorumlusunun hukuki
                  yükümlülüğünü yerine getirebilmesi için zorunlu olması”, “
                  ilgili kişinin kendisi tarafından alenileştirilmiş olması”,
                  “İlgili kişinin temel hak ve özgürlüklerine zarar vermemek
                  kaydıyla, veri sorumlusunun meşru menfaatleri için veri
                  işlenmesinin zorunlu olması”, “Fiili imkânsızlık nedeniyle
                  rızasını açıklayamayacak durumda bulunan veya rızasına hukuki
                  geçerlilik tanınmayan kişinin kendisinin ya da bir başkasının
                  hayatı veya beden bütünlüğünün korunması için zorunlu olması”
                  ve/veya “Kanunlarda açıkça öngörülmesi”
                  <strong>
                    durumlarından herhangi birinin mevcudiyeti
                    halinde,&nbsp;ilgili kişinin açık rızası aranmaksızın
                    kişisel verilerinin işlenmesi mümkündür.
                  </strong>
                  <strong>
                    <br />
                    <br />
                  </strong>
                </p>
                <p>
                  <strong>
                    <br /> v. Özel İşletme Amaçları:{" "}
                  </strong>{" "}
                  Kişisel Bilgilerinizi, onayınız dahilinde, reklam hedeflemesi,
                  veri analizleri, denetimler, dolandırıcılık önleme ve diğer
                  dahili işletme amaçlarımız için de kullanabiliriz. <strong />
                </p>
                <p>
                  <strong>
                    <br /> vi. Alışveriş/ Ticari Hizmetler:{" "}
                  </strong>{" "}
                  Site vasıtasıyla bir satın alım yaptığınızda, kredi kart
                  numarası, fatura adresi ve bu satın almaya ilişkin diğer
                  bilgilerinizi (birlikte “Ödeme Bilgileri”) onayınız dahilinde
                  sizden alabiliriz. Satın alımınızı size faturalandırmak için
                  ve satın alma işleminizi gerçekleştirmek için onayınız
                  dahilinde verdiğiniz Ödeme Bilgilerini kullanacağız. Ödeme
                  Bilgilerinizi vermek suretiyle, siparişinizi teyit etmek ve
                  yerine getirmek için gerektiğinde ilgili üçüncü şahıslara yine
                  onayınız dahilinde ve gerektiği kadarı ile bu bilgileri vermek
                  için bize yetki vermektesiniz (örneğin, kredi kart numaranızı
                  işlemek ve satın aldığınız biletleri göndermek vb).
                </p>
                <p>
                  <strong>
                    <br /> vii. Sosyal medyada paylaşın:{" "}
                  </strong>{" "}
                  Sitemiz, ilan edilen bazı etkinlikleri ya da site
                  özelliklerine linkleri onayınız dahilinde e-posta ya da Sosyal
                  Medya Servisleriyle ilgileneceğine inandığınız diğer kişilere
                  iletmenize imkan tanır.
                </p>
                <p>
                  <strong>
                    <br /> viii. Bülten Aboneliği:
                  </strong>
                </p>
                <p>
                  <strong>
                    <br /> c. İş Başvuruları{" "}
                  </strong>
                </p>
                <p>
                  <br /> Lüleburgaz Belediyesi’e bir iş için başvuru yaptığınızda ve web
                  sitemiz vasıtasıyla kişisel bilgilerinizi gönderdiğinizde,
                  işbu bilgiler sadece başvurunuzu değerlendirmek ve
                  başvurunuzla ilgili işlemleri yapmak için kullanılacaktır.
                  Kişisel bilgilerinizi, mevcut ya da ileride mevcut olacak
                  pozisyonlar için başvurunuzu değerlendirmek amacıyla onayınız
                  dahilinde belli bir süre saklayabiliriz
                </p>
                <p>
                  <strong>
                    <br /> 3.&nbsp;Kişisel verilerinizi aktarabileceğimiz kişi
                    veya kuruluşlar hakkında bilgilendirme:&nbsp;
                  </strong>
                  Yukarıda belirtilen amaçlarla, bizimle paylaştığınız kişisel
                  ve/veya özel nitelikli kişisel verilerinizin onayınız
                  dahilinde aktarılabileceği kişi / kuruluşlar; başta ana
                  hissedarımız olan Ticketmaster A.Ş. ve iştirakleri olmak
                  üzere, doğrudan/dolaylı yurt içi/yurt dışı iştiraklerimiz;
                  faaliyetlerimizi yürütmek üzere hizmet aldığımız/ iş birliği
                  yaptığımız, program-hizmet ortağı şahıs ve kuruluşlar,
                  Bankalar, , biletlerinizi adresinize ulaştıran kurye-kargo
                  firmaları ve diğer ilgili 3. Kişilerdir. Bu meyanda belirtiriz
                  ki, Şirketimiz, birlikte çalıştığı organizatörlerinin etkinlik
                  biletlerinin satış ve dağıtımına aracılıkta bulunduğundan,
                  tarafınıza ait gerekli kişisel ve/veya özel nitelikteki
                  kişisel bilgileri, mevzuat hükümleri kapsamında ve gereklilik
                  dahilinde, etkinlik organizatörleri ile, bankalarla ve/veya
                  talep edilen hizmetin kapsamına göre diğer ilgili üçüncü
                  kişilerle (araç kiralama şirketleri, sigorta şirketleri,
                  kurye/kargo şirketleri ve bunlarla sınırlı olmamak üzere
                  sunulan hizmet ile ilgili kişi ve kuruluşlar) paylaşabilmekte,
                  yurt içine ve/veya yurt dışına aktarabilmektedir.
                </p>
                <p>
                  <strong>
                    <br /> a. Bağlı Ortaklık İletişimleri:&nbsp;{" "}
                  </strong>{" "}
                  Sözleşme kapsamındaki ticari elektronik iletiler, önceden
                  ilgili onaylarınız alınmak kaydıyla sizlere gönderilebilir. Bu
                  onayın, yazılı olarak veya her türlü elektronik iletişim
                  araçlarıyla alınabilmesi mümkün olduğundan, sözleşme
                  kapsamında ticari elektronik ileti gönderilmesini istemez
                  iseniz, “elektronik ileti gönderilmesi” bölümündeki onay
                  işaretini kaldırmanız gerektiğini hatırlatırız. Ayrıca,
                  dilediğiniz zaman, hiçbir gerekçe belirtmeksizin ticari
                  elektronik iletileri almayı reddedebilme hakkına da
                  sahipsiniz. Buna karşın, kendinizle iletişime geçilmesi
                  amacıyla iletişim bilgilerini vermeniz hâlinde, satın
                  aldığınız ya da temin edilen hizmetlere ilişkin olası
                  değişikliklere, iptal ve ertelemelere ve/veya kullanıma
                  yönelik ticari elektronik iletiler için ayrıca onay
                  alınmayacağını da belirtiriz.
                </p>
                <p>
                  <br /> Ayrıca, hizmetin sağlanması sırasında, bu tür
                  haberleşmenin yapılacağına dair sizlerin onayının alınması
                  hâlinde, yeni bir onay alınmaksızın aynı veya benzer
                  hizmetlerle ilgili pazarlama, tanıtım ve/veya değişiklik
                  hizmetleri için haberleşme yapılabilmesi de hukuken mümkündür.
                </p>
                <p>
                  {" "}
                  Bununla birlikte, bu tür haberleşme yapılmasını reddetme ve bu
                  kapsamda verdiğiniz onayları-izinleri geri alma hakkınız
                  saklıdır ve bu talebinizin tarafımıza her türlü yolla
                  iletilmesi yeterlidir. Önceden onaylı e-posta sistemine
                  katılmayı seçtiğiniz takdirde size, bağlı ortaklıklarımız da,
                  ilginizi çekebilecek etkinlikler, ürün ya da hizmetlerin ilan
                  ve bildirilerini gönderebilirler.{" "}
                </p>
                <p>
                  {" "}
                  Dilediğiniz zaman önceden verdiğiniz onayı kaldırabileceğinizi
                  de yineleriz. İlave bilgiler için lütfen aşağıdaki “Önceden
                  Onaylı Seçenekler Politikasına” bölümüne bakın.{" "}
                  <strong>
                    <br />
                    <br />
                  </strong>
                </p>
                <p>
                  <strong>b. Lüleburgaz Belediyesi Servis Sağlayıcıları:&nbsp;</strong> Veri
                  analizi, bilet teslimatı, kredi kart işlemleri ve idari
                  nitelikteki diğer işler de dahil olmak, ancak bunlarla sınırlı
                  olmamak üzere hizmet sağlayıcı olarak üçüncü kişilerle
                  çalışmaktayız. Hizmetlerimizi kullandığınız takdirde, onayınız
                  olması durumunda, Kişisel Bilgilerinizin ilgili üçüncü
                  kişilerle, ilgili hizmetleri sunabilmesi için onayınız
                  çerçevesinde ve gerektiği kadarıyla paylaşılması konusunda
                  hakkımız saklıdır. Bu üçüncü kişilerin de Kişisel
                  Bilgilerinize bizim gösterdiğimiz özeni göstermeleri için tüm
                  gerekli önlemleri almaktayız ve üçüncü kişiler de kişisel
                  bilgilerinizin muhafazasından mevzuat kapsamında veri işleyen
                  olarak sorumlu olacaklardır. <br />
                  <br />
                  <strong />
                </p>
                <p>
                  <strong>c. Diğer Amaçlar:</strong>
                </p>
                <p>
                  <br /> - Bir mahkeme kararı ya da adli-idari makamlardan gelen
                  benzer araştırma talebi, yetkili bir devlet kurumundan gelen
                  işbirliği talebi; yasal haklarımızı tespit etmek ya da
                  uygulamak; hukuki taleplere karşı savunma yapmak; ya da
                  hukuken gereken başka durumlarda da ilgili Kişisel
                  Bilgilerinizi ilgili birimlerle/kişilerle paylaşabilir,
                  herhangi bir hak ya da itirazı kullanabilir ya da bunlardan
                  feragat edebiliriz.
                </p>
                <p>
                  <strong>
                    <br /> 4. Bilgilerin Üçüncü Taraflarca Toplanması{" "}
                  </strong>
                </p>
                <p>
                  <strong>
                    <br /> a. Üçüncü Kişi Reklamları:&nbsp;{" "}
                  </strong>{" "}
                  Sitemiz üzerinde görünen reklamlar size üçüncü kişi reklam
                  ortaklarımız tarafından sunulur. Sitemize reklam veren üçüncü
                  kişiler tarayıcınız üzerine eşsiz bir çerez koyabilir ya da
                  bunu tanıyabilirler. Bir reklama bakma sayınız gibi site
                  ziyaretleriniz hakkındaki Kişisel Olmayan Bilgiler size reklam
                  servisi yapmak için kullanılabilir. <br />
                  <strong>b. Üçüncü Kişi Linkleri: </strong> Sitemiz, Lüleburgaz Belediyesi
                  tarafından sahip olunmayan ya da işletilmeyen, üçüncü kişilere
                  ait web sitelerine, linkler içerebilir. Bu linkleri
                  sadecerahatlık sağlamak amacıyla koyarız. Sitemiz üzerine bir
                  linkin konulması Lüleburgaz Belediyesi’in link verilen siteye onay verdiği
                  ya da ilgili sitenin güvenilir-güvenli olduğu olduğu anlamına
                  gelmez.
                </p>
                <p>
                  {" "}
                  Herhangi böyle bir üçüncü kişi web sitesine Kişisel
                  Bilgilerinizi verdiğiniz takdirde, işleminiz bu üçüncü tarafın
                  web sitesince ve ilgili üçüncü tarafça (www.luleburgaz.bel.tr
                  tarafından değil) gerçekleştirilecek ve verdiğiniz Kişisel
                  Bilgileriniz o üçüncü taraf tarafından toplanacak ve onların
                  gizlilik politikasıyla kontrol edilecektir. Bu web sitelerinin
                  sizin onlara verdiğiniz herhangi bir Kişisel Bilgiyi
                  kullanması dahil kendi gizlilik uygulamaları ya da bu üçüncü
                  taraf web sitelerinin içeriğinden hiçbir şekilde Lüleburgaz Belediyesi
                  sorumlu değildir. Yine, Lüleburgaz Belediyesi, link verilen diğer web
                  sitelerinin gizlilik uygulamaları veya içeriklerinden veya
                  üçüncü kişilerin gizlilik politikaları ve uygulamalarından
                  veya üçüncü kişilerin topladığı, işlediği-aktardığı ya da
                  kullandığı “kişisel veriler” den de hiçbir şekilde sorumlu
                  değildir. <br />
                  <strong>
                    <br /> 5. Onayınız Dahilindeki Önceden Onaylı Seçenekler
                    Politikası
                  </strong>
                </p>
                <p>
                  <strong>
                    <br /> a. Lüleburgaz Belediyesi İletişimleri:{" "}
                  </strong>{" "}
                  Lüleburgaz Belediyesi’e iletişim Bilgilerinizi verdiğinizde ve iletişim
                  onayınız olduğunda, zaman zaman Lüleburgaz Belediyesi’in takdirinde olmak
                  üzere, bizden Site, Lüleburgaz Belediyesi ve/veya reklamcılarımız ve
                  ortaklarımızla ilgili olarak özel teklifler hakkında
                  bilgilendirme almayla ilgilenip ilgilenmediğinizi belirtmeniz
                  istenebilir. Bu iletişimleri almak için kaydolmayı
                  seçtiğinizde zaman zaman pazarlama iletişimleri
                  gönderebiliriz. Dilediğiniz zaman, bu iletişimleri almaktan
                  tek yanlı şekilde vazgeçebilme hakkına sahip olduğunuzu da
                  hatırlatırız.
                </p>
                <p>
                  <br /> Herhangi bir zamanda Hesabım’ı tıklayarak bizden
                  haftalık etkinlik bilgilendirmesi ve promosyon e-postaları
                  almamayı seçebilirsiniz. Seçimleri herhangi bir zamanda
                  Hesabım sekmesinden değiştirebilirsiniz. Ayrıca, size,
                  gönderdiğimiz e-postalarda “abonelikten çık” diyen e-postanın
                  altındaki link üzerine tıklamak suretiyle gelecekte bu
                  mesajları almaktan vazgeçme hakkına da sahipsiniz. Bir
                  kayıttan çıkarılma talebini yerine getirmemiz 3 işgününe kadar
                  bir zaman alabilir.
                  <br /> Bizden etkinlik ve promosyon e-postalarını alma
                  kaydından çıkmış olsanız bile, Lüleburgaz Belediyesi’ten satın aldığınız
                  hizmetlere ve etkinlik biletlerine dair e-posta iletişimlerini
                  size gönderebiliriz. Bunlar işleminizin durumu, hizmetlerin-
                  biletlerinizin teslimatı ve etkinliğiniz ile ilgili güncel
                  bilgiler içeren müşteri hizmetleri ile ilgili e-postalar
                  olabilir.
                </p>
                <p>
                  <br /> Hesabım’daki bilgileri değiştirmenin ya da e-posta
                  iletişimleri listesinden çıkmanın, sadece gelecekte bizden
                  alacağınız iletişimleri etkileyeceğini unutmayın.
                  Bilgilerinizi, siz tercihlerinizi değiştirmeden önce ya da
                  güncellemeden önce, onayınız dahilinde bir üçüncü kişiye
                  göndermişsek, bu üçüncü kişiyle ilgili tercihlerinizi de
                  ayrıca değiştirmeniz gerekebilir.
                </p>
                <p>
                  <strong />
                </p>
                <p>
                  <strong>
                    <br /> b. Bağlı Ortaklık ve Üçüncü Kişi İletişimleri:{" "}
                  </strong>{" "}
                  Zaman zaman Lüleburgaz Belediyesi’in takdirinde ve onayınız dahilinde, size,
                  bağlı ortaklıklarımız ve/veya üçüncü kişilerin iletişim
                  listesine kaydolma fırsatı verilebilir. Herhangi böyle bir
                  teklif(ler)i kabul etmeyi seçtiğiniz takdirde, bu amaçlarla
                  Lüleburgaz Belediyesi’e verdiğiniz tüm iletişim Bilgilerinizi, onayınız
                  dahilinde ve gerektiği kadarı ile bağlı ortaklıklarımız ya da
                  bu üçüncü taraflarla paylaşabiliriz. Fikrinizi değiştirir ve
                  artık iletişim Bilgilerinizi pazarlama amaçları için bağlı
                  ortaklıklarımız ve/veya üçüncü taraflarla paylaşmak
                  istemezseniz, yukarıdaki prosedürü takip ederek herhangi bir
                  zamanda gelecekteki iletişim paylaşımından çıkabilirsiniz.
                  <strong>
                    <br /> 6. Sitenin Çocuklar tarafından kullanımı:&nbsp;{" "}
                  </strong>{" "}
                  Site on üç (13) yaşından küçük bireylere yönelik değildir ve
                  bu bireylerin sitemiz vasıtasıyla KişiselBilgilerini ya da
                  iletişim bilgilerini vermelerini istemiyoruz. Bir ebeveyn ya
                  da veli, çocuğunun bize kişisel bilgilerini ebeveyn ya da
                  velisinin izni olmadan (örneğin yaşını yanlış göstererek)
                  verdiğini fark ederse; bu veli ya da ebeveyn bize
                  info@luleburgaz.bel.tr‘den ulaşmalıdır. Lüleburgaz Belediyesi kişinin hatalı
                  yönlendirmesi sebebiyle, 13 yaşın altındaki çocukların kişisel
                  bilgilerini aldığını herhangi bir şekilde tespit ederse derhal
                  13 yaşından küçük çocukların sahibi olduğuna inanılan bu
                  bilgileri ve hesapları silecektir. Lütfen, yetişkin olmayanlar
                  tarafından Sitenin kullanımına ilişkin ilave bilgi için
                  Kullanım Hükümlerine bakın.
                  <br />
                  <strong />
                </p>
                <p>
                  <strong />
                </p>
                <p>
                  <strong />
                </p>
                <p>
                  <strong>
                    <br /> 7.Sizin Hakkınızda Toplanan Bilgilere Erişim,
                    Güncelleme ya da Bunları Silme:&nbsp;{" "}
                  </strong>{" "}
                  Sitemiz ayrıca, KVKK kapsamında, sizlere, bize verdiğiniz
                  Kişisel Bilgilerinizi silme ya da değiştirme fırsatı da verir.
                  Bunu Hesabım’ı tıklayarak ve sitemiz üzerindeki kullanıcı
                  bilgilerinizi değiştirmek için talimatları takip ederek
                  yapabilirsiniz. Bu bilgilerin veritabanlarımızda olması
                  kaydıyla, değişiklik taleplerinize yasal hükümler kapsamında
                  mümkün olan en kısa zamanda cevap vereceğiz.. KVKK kapsamında
                  sahip olduğunuz diğer haklara ilişkin olarak da, işbu metnin
                  yukarıda aktardığımız “{" "}
                  <strong>
                    Kullanıcıların Kişisel Verilerine İlişkin Hakları”
                  </strong>{" "}
                  bölümüne tekrar bakabilirsiniz.
                  <strong>
                    <br /> 8. Kişisel Bilgilerinizin Güvenliği:{" "}
                  </strong>
                  Lüleburgaz Belediyesi, sitesi vasıtasıyla toplanan Kişisel Bilgilerin
                  güvenliğini sağlamak ve verilere izinsiz erişimi engellemek
                  için fiziksel, elektronik ve operasyonsal tedbirler dahil
                  olmak üzere, mevzuat hükümleri kapsamında uygun güvenlik
                  tedbirlerini alır ve kullanır.
                </p>
                <p>
                  {" "}
                  Lüleburgaz Belediyesi, kontrolü altındaki Kişisel Bilgileri korumak için tüm
                  gerekli tedbirleri alsa da internet üzerindeki tüm veri
                  iletişimleri ya da veri depolama şekillerinin %100 güvenli
                  olduğu garanti edilemez. Bu veri transferleri sırasında
                  bilgilerin yolunun kesilmesi, değiştirilmesi ya da yanlış
                  kullanımı sonucu maruz kalacağınız herhangi bir zarar için
                  sorumluluk üstlenmemekteyiz. Sitenin güvenliğinden herhangi
                  bir şekilde endişe duyduğunuzda lütfen bizimle&nbsp;
                  <a href="mailto:info@luleburgaz.bel.tr">info@luleburgaz.bel.tr</a>‘dan
                  temasa geçin.
                </p>
                <p>
                  <strong>
                    <br /> 9. Çevimiçi ve Çevrimdışı Uygulamalar:&nbsp;{" "}
                  </strong>{" "}
                  Bu Gizlilik Politikasının, sitemizle bağlantılı olarak sadece
                  çevrimiçi bilgi toplama ve yayılması uygulamalarında geçerli
                  olduğunu ve çevrimdışı gerçekleştirilen herhangi bir
                  uygulamamız için geçerli olmadığını unutmayın. <br />
                  <strong>
                    <br /> 10. Siteye İlişkin Olarak Lüleburgaz Belediyesi'le İrtibata Geçme:{" "}
                  </strong>{" "}
                  Bu Politikaya ilişkin herhangi bir sorunuz, görüşünüz ya da
                  farklı bir talebiniz olduğunda, bu soruları, görüşlerinizi
                  ve/veya taleplerinizi emaille,&nbsp;
                  <a href="mailto:info@luleburgaz.bel.tr">info@luleburgaz.bel.tr</a>‘ye
                  gönderebilirsiniz. E-posta iletişimlerinin mutlaka güvenli
                  olmayabileceğini unutmayın. Bu sebeple, kredi kartı
                  bilgilerinizi ve diğer hassas bilgileri bizimle e-posta
                  yazışmalarınızda kullanmayın. <br />
                  <br /> © 2023 T.C. Lüleburgaz Belediyesi. Tüm hakları saklıdır. <strong />
                  {/* <a href="">Lüleburgaz Belediyesi Satış Politikaları ve Satış Sözleşmesini PDF olarak indirin.</a> */}
                </p>
              </div>
            </span>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default GizlilikPage;

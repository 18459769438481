import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import React, { FC } from "react";
import { Helmet } from "react-helmet";
import { config } from "config";
import { EventsType } from "data/types";
import HomeEventCard from "components/HomeEventCard/HomeEventCard";
import Heading from "shared/Heading/Heading";
import { useParams, useNavigate } from "react-router-dom";

export interface ListingStayPageProps {
  className?: string;
}

const ListingStayPage: FC<ListingStayPageProps> = ({ className = "" }) => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [items, setItems] = React.useState<any[]>([]);
  const [category, setCategory] = React.useState<any['title']>();
  const [loading, setLoading] = React.useState(true);
  const fetchItems = async () => {
    setLoading(true);
    fetch(config.apiUrl + config.getEventsByCategory + "/" + id)
      .then((res) => res.json())
      .then(
        (result) => {
          setItems(result);
          setLoading(false);
        },
        (error) => {}
      );
  };
  const getCategoryDetail = async () => {
    setLoading(true);
    fetch(config.apiUrl + config.getEventCategoryDetail + "/" + id)
      .then((res) => res.json())
      .then(
        (result) => {
          setCategory(result);
          setLoading(false);
        },
        (error) => {}
      );
  };
  React.useEffect(() => {
    fetchItems();
    getCategoryDetail();
  }, []);
  const renderCard = (stay: EventsType, index: number) => {
    return <HomeEventCard key={index} className="h-full" data={stay} />;
  };
  return (
    <div
      className={`nc-ListingStayPage relative overflow-hidden ${className}`}
      data-nc-id="ListingStayPage"
    >
      <Helmet>
        <title>Lüleburgaz Belediyesi - Etkinlik Hizmeti</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative overflow-hidden py-10">
        <div className="nc-SectionGridFeatureProperty relative">
          <Heading desc={`${category?.event_count} adet etkinlik bulundu`} isCenter={true}>
          {category?.title}
          </Heading>
          <div
            className={`grid gap-6 md:gap-8 grid-cols-1 sm:grid-cols-1 xl:grid-cols-2`}
          >
            {items && items.map(renderCard)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingStayPage;

import React, { FC, useEffect, useMemo } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import { EventsType } from "data/types";
import NextPrev from "shared/NextPrev/NextPrev";
import useNcId from "hooks/useNcId";
import { Link, useNavigate } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import { config } from "config";
import Skeleton from "react-loading-skeleton";
import HomeEventCard from "components/HomeEventCard/HomeEventCard";

export interface SectionSliderNewCategoriesProps {
  className?: string;
  heading?: string;
  subHeading?: string;
  category_id?: number;
  categoryCardType?: string;
  categories?: string;
  itemPerRow?: 4 | 5;
  sliderStyle?: "style1" | "style2";
  uniqueClassName: string;
}

const SectionSliderNewCategories: FC<SectionSliderNewCategoriesProps> = ({
  heading = "Heading of sections",
  subHeading = "Descriptions for sections",
  className = "",
  category_id = 0,
  itemPerRow = 5,
  sliderStyle = "style1",
  uniqueClassName,
}) => {
  const UNIQUE_CLASS =
    "SectionSliderNewCategories__" + uniqueClassName + useNcId();
  const navigate = useNavigate();
  const [items, setItems] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(true);
  const fetchItems = async () => {
    setLoading(true);
    fetch(config.apiUrl + config.getSimiliarEvents + "/" + category_id)
      .then((res) => res.json())
      .then(
        (result) => {
          setItems(result);
          setLoading(false);
        },
        (error) => {console.error(error)}
      );
  };
  let MY_GLIDEJS = useMemo(() => {
    return new Glide(`.${UNIQUE_CLASS}`, {
      perView: itemPerRow,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          perView: itemPerRow - 1,
        },
        1024: {
          gap: 20,
          perView: itemPerRow - 1,
        },
        768: {
          gap: 20,
          perView: itemPerRow - 2,
        },
        640: {
          gap: 20,
          perView: itemPerRow - 3,
        },
        500: {
          gap: 20,
          perView: 1.3,
        },
      },
    });
  }, [UNIQUE_CLASS]);
  React.useEffect(() => {
    setTimeout(() => {
      fetchItems();
    },1000);
    
  }, [MY_GLIDEJS, UNIQUE_CLASS]);

  useEffect(() => {
    setTimeout(() => {
      MY_GLIDEJS.mount();
    }, 100);
  }, [items]);

  const renderCard = (item: EventsType, index: number) => {
    return <HomeEventCard key={index} className="h-full" data={item} />;
  };
  return (
    <div className={`nc-SectionSliderNewCategories ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`}>
        <Heading
          desc={subHeading}
          hasNextPrev={sliderStyle === "style1"}
          isCenter={sliderStyle === "style2"}
        >
          {heading}
        </Heading>
        {loading && (
          <Skeleton
            height={300}
            baseColor="#0c1222"
            highlightColor="#171e30"
            borderRadius={36}
          />
        )}
        <div
            className={`grid gap-6 md:gap-8 grid-cols-1 sm:grid-cols-1 xl:grid-cols-2`}
          >
        {!loading && items &&
          items.map((item: EventsType, index: any) => {
            return (
              <div key={index}>
                {renderCard(item, index)}
              </div>
            )
          })
        }
        </div>
      </div>
    </div>
  );
};

export default SectionSliderNewCategories;
